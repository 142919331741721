import React, { Component } from "react";
import { Helmet } from "react-helmet";
import OrderStep from '../components/orderstep'
import { TabComponent, TabItemDirective, TabItemsDirective } from '@syncfusion/ej2-react-navigations';
import ShoppingCart from '../components/shoppingCart';
import ProductCard from '../components/productCard';
import {Animated} from "react-animated-css";

export default class Order extends Component {
    constructor(props) {
        super(props);

        let order = null;

        if (localStorage.getItem('order')) {
            order = JSON.parse(localStorage.getItem('order'));            
        }

        let orderTotal = 0;
        
        if (order != null && order.shoppingCart != null && order.shoppingCart?.length > 0) {
            order.shoppingCart.map((item) => (
                orderTotal += (item.price * item.quantity)
            ))
        }
        else {
            order = null;
        }

        this.headerTabText = [{ text: "מאפיינים טכנים" }, { text: "אזהרות כלליות" }, { text: "אחריות" }, { text: "תכולת האריזה" }];
        
        this.state = {
            order,
            orderTotal
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }
    
    handleChange = (e) => {
        const value = e.target.value;
        const name = e.target.name;                

        this.setState({
            ...this.state,
            [name]: value
        });
    }

    updateOrder = () => {
        if (localStorage.getItem('order')) {
            let order = JSON.parse(localStorage.getItem('order'));

            let orderTotal = 0;
        
            if (order != null && order.shoppingCart != null && order.shoppingCart?.length > 0) {
                order.shoppingCart.map((item) => (
                    orderTotal += (item.price * item.quantity)
                ))
            }
            else {
                order = null;
            }

            this.setState({ order, orderTotal });
        }
    };

    continueClick = () => {
        const { orderCount, orderColor, price, name, image } = this.state;

        let order = {};
        const shoppingCart = [{
            quantity: orderCount,
            productId: orderColor,
            price,
            name,
            image
        }];

        if (localStorage.getItem('order')) {
            // order = JSON.parse(localStorage.getItem('order'));
            // order = { ...order,
            //     shoppingCart: shoppingCart
            // };
            this.props.history.push('/shipping');
        }
        else {
            order = { 
                shoppingCart: shoppingCart
            };

            localStorage.setItem('order', JSON.stringify(order));
            this.props.history.push('/shipping');
        }
    }

    contentTab0() {
        return <div className="py-3 content-tab">
        <ul>            
            <li>דרישות להפעלת מערכת אנדרואיד: התאמה למערכת ההפעלה 5.0 ואילך</li>
            <li>דרישות להפעלת מערכת iOS: התאמה למערכת ההפעלה 11.0 ואילך</li>
            מומלץ לבדוק מראש את התאמת הסמארטפון  בשימוש לגירסת האפליקציה הזמינה.
            <li>טכנולוגיית תקשורת: Bluetooth BLE-Low Energy</li>
            <li>פס תדרים  2.4-2.480 GHz</li>
            <li>עוצמת שידור מרבית :10 mW</li>
            <li>ספק כוח: 2 סוללות AAA חד שימושיות, הניתנות להחלפה על ידי המשתמש (ראה הוראות בסעיף 6.2)</li>
            <li>טמפרטורת פעולה:-20°C +60°C</li>
        </ul>
      </div>;
    }

    contentTab1() {
        return <div className="py-3 content-tab">
        <ul>
            <li>הרחיקו את אריזת המוצר מהישג ידם של ילדים מאחר שהיא מהווה מקור סכנה פוטנציאלי</li>
            <li>לפני השימוש במוצר babybeep®, בדקו שהוא שלם. במידה והוא פגום, אנא פנו אל החנות בה נרכש ואל תשתמשו במוצר כלל</li>
            <li>babybeep® מצויד בשתי סוללות אלקליין AAA. שמרו את babybeep® הרחק ממקורות חום, כמו רדיאטורים, תנורי חימום, תנורי בישול, להבות אש וכדומה</li>
            <li>במידה ונתקלתם בחוטים שאינם במקומם, אל תנסו לתקן בעצמכם. פנו בהקדם לחנות בה נקנה המוצר</li>
            <li>הקפידו לשמור על חיבור Bluetooth™ ומעקב GPS בזמן הסעת ילדכם ברכב, בכדי להבטיח פעילות תקינה</li>
            <li>בדקו גם שיש לכם מספיק קליטה בסמארטפון המשוייך, מכיוון שהיעדר תנועת נתונים לא תאפשר תפעול תקין של המערכת</li>
        </ul>
      </div>;
    }

    contentTab2() {
        return <div className="py-3 content-tab">
            האחריות הינה מלאה על כל רכיבי המוצר, תוקף האחריות הינו לשנה מיום הרכישה וכנגד הצגת חשבונית, המוצר מבוטח עבור כל פגם בתנאי שימוש רגילים המתוארים בהוראות השימוש במדריך למשתמש, האחריות לא תחול כתוצאה משימוש לא נכון במוצר, פגמים בזדון, מים, בלאי, פגיעה ברכיבים האלקטרוניים או אירועים מקריים. ניתן להאריך את האחריות לשנה נוספת (סה״כ שנתיים אחריות מלאה) באמצעות אתר החברה תמורת 59 ש״ח תשלום חד פעמי, ובתנאי שנעשה עד שלושה ימים מיום הרכישה.
      </div>;
    }

    contentTab3() {
        return <div className="py-3 content-tab">
        <ul>
            <li>מוצר babybeep®</li>
            <li>2 סוללות אלקליין AAA שתוכנסנה למחזיק הסוללות המיועד להפעלת המוצר</li>
            <li>מדריך המשתמש</li>
            <li>מדבקת חלון שתודבק על חלון הרכב על מנת להעיד על שימוש במכשיר נגד שכחת ילדים, פעולת ההדבקה על החלון תבוצע בהקפדה ומחשבה יתרה, בכדי למנוע הפרעה בשדה הראייה בזמן הנהיגה ברכב</li>
        </ul>
      </div>;
    }

    render() {

        const { order, orderTotal } = this.state;        

        return (
            <>
                <Helmet> 
                    <title>Baby beep - הזמן עכשיו</title>
                    <meta property="og:title" content="Baby beep - הזמן עכשיו" />
                    <meta name="description" content="ביפ קטן בשבילכם, ביטחון גדול לילדיםעם השלמת הרכישה, תוכלו גם אתם ליהנות מהביטחון המלא שהילדים שלכם שמורים ובטוחים ולא ישכחו ברכב. בחרו כמות וצבע, והכריות בדרך אליכם עם משלוח חינם עד הבית." />
                    <meta property="og:description" content="ביפ קטן בשבילכם, ביטחון גדול לילדיםעם השלמת הרכישה, תוכלו גם אתם ליהנות מהביטחון המלא שהילדים שלכם שמורים ובטוחים ולא ישכחו ברכב. בחרו כמות וצבע, והכריות בדרך אליכם עם משלוח חינם עד הבית." />
                </Helmet>

                <form noValidate autoComplete="off">
                    <div className="container order py-5">
                        <div className="row">
                            <div className="col-12 mb-5">
                                <OrderStep
                                    step={1}>
                                </OrderStep>
                            </div>
                        </div>
                        { order != null &&
                            <div className="row">
                                <div className="col-12 pb-5">
                                    <ShoppingCart
                                        shoppingCart={order.shoppingCart}
                                        orderTotal={orderTotal}
                                        orderTotalBeforeCoupon={orderTotal}
                                        showBack={false}
                                        showEdit={true}
                                        {...this.props}
                                        updateOrder = {this.updateOrder}
                                    >    
                                    </ShoppingCart>
                                </div>
                            </div>
                        }
                        <Animated animationIn="slideInRight">
                            <div className="row">
                                <div className="col">
                                    <h1 className="text-center">ביפ קטן בשבילכם, ביטחון גדול לילדים</h1>
                                    <h2 className="text-right py-4">
                                        עם השלמת הרכישה, תוכלו גם אתם ליהנות מהביטחון המלא שהילדים שלכם שמורים ובטוחים ולא ישכחו ברכב.
                                        בחרו כמות וצבע, והכריות בדרך אליכם עם <span className="font-weight-bold">משלוח חינם עד הבית</span>.
                                    </h2>
                                </div>
                            </div>
                        </Animated>
                        <div className="row mb-5">
                            <div className="col-md-4 mb-3">
                                <ProductCard
                                    productId = {1}
                                    productName = "Baby beep תכלת"
                                    productPrice = {299}
                                    productPriceOld= {499}
                                    productCurrency = '₪'
                                    productImage = 'https://static.babybeep.co.il/images/babybeep-blue.jpg'
                                    updateOrder = {this.updateOrder}
                                >    
                                </ProductCard>
                            </div>
                            <div className="col-md-4 mb-3">
                                <ProductCard
                                    productId = {2}
                                    productName = "Baby beep ורוד"
                                    productPrice = {299}
                                    productPriceOld= {499}
                                    productCurrency = '₪'
                                    productImage = 'https://static.babybeep.co.il/images/babybeep-pink.jpg'
                                    updateOrder = {this.updateOrder}
                                >    
                                </ProductCard>
                            </div>
                            <div className="col-md-4 mb-3">
                                <ProductCard
                                    productId = {3}
                                    productName = "Baby beep אפור"
                                    productPrice = {299}
                                    productPriceOld= {499}
                                    productCurrency = '₪'
                                    productImage = 'https://static.babybeep.co.il/images/babybeep-gray.jpg'
                                    updateOrder = {this.updateOrder}
                                >    
                                </ProductCard>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <TabComponent>
                                    <TabItemsDirective>
                                        <TabItemDirective header={this.headerTabText[0]} content={this.contentTab0}/>
                                        <TabItemDirective header={this.headerTabText[1]} content={this.contentTab1}/>
                                        <TabItemDirective header={this.headerTabText[2]} content={this.contentTab2}/>
                                        <TabItemDirective header={this.headerTabText[3]} content={this.contentTab3}/>
                                    </TabItemsDirective>
                                </TabComponent>
                            </div>
                        </div>
                    </div>
                </form>
            </>
        );
    }
}