import React, { Component } from "react";
import { Helmet } from "react-helmet";

export default class Article100001 extends Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <>
                <Helmet> 
                    <title>שכחתם את הילד לרגע תצטערו כל החיים!</title>
                    <meta property="og:title" content="שכחתם את הילד לרגע תצטערו כל החיים!" />
                    <meta name="description" content="בכל פעם שאנחנו שומעים על עוד פעוט שנשכח במכונית לוהטת, אנחנו מזדעזעים מהטרגדיה הנוראית והמיותרת ואוטומטית מניחים שמדובר בעוד מקרה של הורים מזניחים וחסרי אחראיות. אבל גם אם נניח שזה המקרה ומדובר באופן גורף באוסף הורים מזניחים, איך ניתן להסביר את שכיחות התופעה בכל רחבי העולם? זאת בדיוק השאלה שעמדה בבסיס מחקר בן 12 שנה של אונ' קולומביה שמוכיח ללא צל של ספק, שכחת ילדים במכונית, באמת עלולה לקרות לכל אחת ואחד מאיתנו!" />
                    <meta property="og:description" content="בכל פעם שאנחנו שומעים על עוד פעוט שנשכח במכונית לוהטת, אנחנו מזדעזעים מהטרגדיה הנוראית והמיותרת ואוטומטית מניחים שמדובר בעוד מקרה של הורים מזניחים וחסרי אחראיות. אבל גם אם נניח שזה המקרה ומדובר באופן גורף באוסף הורים מזניחים, איך ניתן להסביר את שכיחות התופעה בכל רחבי העולם? זאת בדיוק השאלה שעמדה בבסיס מחקר בן 12 שנה של אונ' קולומביה שמוכיח ללא צל של ספק, שכחת ילדים במכונית, באמת עלולה לקרות לכל אחת ואחד מאיתנו!" />
                    <meta property='og:type' content='article' />
                </Helmet>
                
                <div className="container article py-5">
                    <div className="row">
                        <div className="col-md-12 my-3">
                            <h1>שכחתם את הילד לרגע תצטערו כל החיים!</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 my-3">
                            <h2>בכל פעם שאנחנו שומעים על עוד פעוט שנשכח במכונית לוהטת, אנחנו מזדעזעים מהטרגדיה הנוראית והמיותרת ואוטומטית מניחים שמדובר בעוד מקרה של הורים מזניחים וחסרי אחראיות. אבל גם אם נניח שזה המקרה ומדובר באופן גורף באוסף הורים מזניחים, איך ניתן להסביר את שכיחות התופעה בכל רחבי העולם? זאת בדיוק השאלה שעמדה בבסיס מחקר בן 12 שנה של אונ' קולומביה שמוכיח ללא צל של ספק, שכחת ילדים במכונית, באמת עלולה לקרות לכל אחת ואחד מאיתנו!</h2>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-md-12 text-justify">
                            <h3>שכחתם צרור מפתחות או סלולרי על השידה? זה יכול לקרות גם לכם</h3>
                            <p>
                                פרופ' דיאמונד מאונ' קולומביה שהוביל את צוות החוקרים, במחקר המקיף והגדול ביותר שנעשה על התופעה משתף: "אני חוקר את המוח והזיכרון מאז 1980, אבל חשתי מבוכה בכל פעם בה נשאלתי כיצד הורים מסוגלים לשכוח את ילדיהם במכונית ולהמשיך בענייני יומם. זה בלתי נתפש. ההנחה הראשונית שלי הייתה שמדובר בהזנחה הורית"
                            </p>
                            <p>
                                כיום, 12 שנה מאוחר יותר, לפרופ' דיאמונד יש תשובה אחרת לחלוטין לשאלה מיהם האנשים המסוגלים לשכוח את ילדם במכונית — "כולנו, או לפחות כל מי שאי פעם שכח משהו, מתישהו
                                שכחתם צרור מפתחות על השידה? פספסתם פגישת עבודה? אם כך, זה יכול לקרות גם לכם" כך לטענת דיאמונד. כשל כזה הוא תוצאה של תחרות בין 2 מערכות זיכרון מקבילות. "זיכרון מתוך הרגל" ו-"זיכרון עתידי" זיכרון מתוך הרגל אחראי על פעולות חוזרות שמבוצעות באוטומט, כגון נסיעה מהבית לעבודה. ואילו זיכרון עתידי, אחראי על תכנון מטלות קדימה. בכל פעם כשדעתנו מוסחת והטייס האוטומטי מופעל. מערכת הזיכרון מתוך הרגל גוברת ואנחנו שוכחים מטלות חשובות. כן, גם אם מדובר בהוצאת הדבר היקר לנו ביותר בחיים מהרכב בתום הנסיעה.
                            </p>

                            <h3>ההבדל בין סוף טוב לטרגדיה הוא עניין של דקות</h3>
                            <p>
                                בחודשים ינואר עד יולי בשנת 2019 חולצו בישראל 729 ילדים מכלי רכב נעולים, מתוכם 4 שנשכחו ברכב על ידי הורה או נהג-מסיע. במהלכה של שנת 2018 המספרים אף היו גבוהים יותר – 1,104 תינוקות, פעוטות וילדים חולצו, כש-9 מתוכם נשכחו ברכב.
                            </p>
                            <p>
                                המרחק בין הופעה בסטטיסטיקה בעמודת ה"חולץ" לבין פגיעה חמורה עד מוות, הוא עניין של דקות ספורות, הדקות בהן מתרחשת העלייה העיקרית בטמפרטורות, אשר יכולות להגיע, בשיאן, גם עד ל-70 מעלות צלזיוס. בדקות אלו, תגובה מהירה של ההורה, המטפלת או הנהג המסיע והסיפור עשוי להיגמר אחרת לגמרי.
                            </p>

                            <h3>אחרי סחבת של עשור, המדינה מיישרת קו עם החדשנות הטכנולוגית</h3>
                            <p>
                                ממש לאחרונה, אחרי למעלה מ-10 שנים של דיונים, וועדות וסחבת מיותרת אישר משרד התחבורה את התקנה המחייבת התקנת מערכת התרעה נגד שכחת ילדים ברכב. 
                                שרת התחבורה רגב צוטטה: "לאחר שנים של מקרי מוות טראגיים הובלתי מציאת פתרון לטרגדיות הקשות. לאחר עבודת מטה של כל הגורמים, התקנות יובאו לידי מימוש. 
                                כבר הקיץ כל כלי רכב יחוייב בהתקנת מערכת וכך נציל חיי ילדים".
                            </p>
                            <p className="font-italic">
                                אכיפת התקנה תחל מינואר 2022, מי שייתפס מסיע קטין מתחת לגיל 4 ללא המערכת, ייקנס ב-250 שקלים וארבע נקודות חובה לנהג ותיחשב לו כעבירה פלילית. 
                            </p>

                            <h3>ביפ אחד והילדים בטוחים</h3>
                            <p className="font-weight-bold">
                                אחת ממערכות מניעת שכחת הילדים המתקדמות, אם לא המתקדמת שבהן שנמצאת בשימוש שנים בשוק האירופאי והגיעה לישראל ממש לאחרונה היא בכלל כרית מהפכנית בשם בייבי ביפ, פרי פיתוח ישראלי בשילוב עם ייצור איטלקי קפדני.
                            </p>
                            <p>
                                כרית בייבי ביפ מזהה שמכשיר הטלפון הנייד שלכם מתרחק מהרכב ושולחת לסמארטפון התראה וצפצוף עד שתחזרו לרכב. לא חזרתם תוך 2 דק'? המערכת תתריע לאנשי קשר נבחרים כולל מיקום אחרון בו ממוקם הרכב.  כך שעם בייבי ביפ אתם בטוחים שהילדים בטוחים
                            </p>
                            <p>
                                *זיהוי התרחקות הנייד שלכם מהרכב ללא הילד
                                <br></br>
                                *שליחת התראה מיידית לנייד בליווי צפצוף
                                <br></br>
                                *התראה לאנשי קשר נבחרים במידה ולא חזרתם
                                <br></br>
                                *עומד בתקני הבטיחות האירופאיים המחמירים ביותר
                                <br></br>
                                *ללא חוטים והתקנה
                                <br></br>
                                *שנה אחריות
                                <br></br>
                                *נוח להעברה בין כיסאות
                                <br></br>
                                *מופעל בסוללות AAA
                            </p>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}