import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

export default ({step = null}) => {
  return (
    <div className="row orderstep text-center">
        <div className={step === 1 ? "col-4 active" : "col-4"}>{step > 1 ? <Link to="/order">עגלת קניות</Link> : "עגלת קניות"}</div>
        <div className={step === 2 ? "col-4 active" : "col-4"}>{step > 2 ? <Link to="/shipping">פרטי משלוח</Link> : "פרטי משלוח"}</div>
        <div className={step === 3 ? "col-4 active-last" : "col-4"}>פרטי תשלום</div>
    </div>
  );
};