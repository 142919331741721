import React, { Component } from "react";
import { Helmet } from "react-helmet";
import OrderStep from '../components/orderstep'
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { TextBoxComponent, FormValidator } from '@syncfusion/ej2-react-inputs';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import HomeIcon from '@material-ui/icons/Home';
import citiesList from '../data/cities.json';
import streetsList from '../data/streets.json';
import{ Browser } from'@syncfusion/ej2-base';

export default class Shipping extends Component {
    constructor(props) {
        super(props);

        let order = null;

        if (localStorage.getItem('order')) {
            order = JSON.parse(localStorage.getItem('order'));
        }
        else {
            this.props.history.push('/order');
        }

        let orderTotal = 0;
        
        if (order != null) {
            order.shoppingCart.map((item) => (
                orderTotal += (item.price * item.quantity)
            ))
        }

        let streets = [];
        const shippingCityId = order?.shipping?.shippingCityId || '';
        const shippingStreetId = order?.shipping?.shippingStreetId || '';

        if (shippingStreetId !== "") {
            streetsList.forEach(({ StreetId, Name, CityId }) => {
                if (CityId === shippingCityId) {
                    streets.push({ StreetId, Name });
                }
            });
        }

        this.state = {
            fullname: order?.shipping?.fullname || '',
            email: order?.shipping?.email || '',
            phone: order?.shipping?.phone || '',
            shippingCityId,
            shippingStreetId,
            shippingHome: order?.shipping?.shippingHome || '',
            shippingApartment: order?.shipping?.shippingApartment || '', 
            shippingZipcode: order?.shipping?.shippingZipcode || '',
            shippingContent: order?.shipping?.shippingContent || '',
            cities: citiesList,
            streetsAll: streetsList,
            streets,
            shippingContentLength: (order?.shipping?.shippingContent.length || 0),
            order,
            orderTotal
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0)

        this.getAllInformation();

        const options = {
            rules: {
                'fullname': {
                    required: [true, 'שדה חובה'],
                    maxLength: [50, 'מקסימום {0} תווים']
                },
                'email': {
                    required: [true, 'שדה חובה'],
                    email: [true, 'אימייל לא חוקי'],
                    maxLength: [256, 'מקסימום {0} תווים']
                },
                'phone': {
                    required: [true, 'שדה חובה'],
                    number: [true, 'מספר טלפון לא תקני'],
                    minLength: [8, 'מספר טלפון לא תקני'],
                    maxLength: [20, 'מקסימום {0} תווים']
                },
                'shippingCityId': {
                    required: [true, 'שדה חובה']
                },
                'shippingStreetId': {
                    required: [true, 'שדה חובה']
                },
                'shippingHome': {
                    required: [true, 'שדה חובה'],
                    maxLength: [50, 'מקסימום {0} תווים']
                },
                'shippingApartment': {                    
                    maxLength: [50, 'מקסימום {0} תווים']
                },
                'shippingZipcode': {
                    minLength: [7, 'מיקוד {0} תווים'],
                    maxLength: [7, 'מיקוד {0} תווים']
                },
                'shippingContent': {
                    maxLength: [500, 'מקסימום {0} תווים']
                },
            }
        }

        // initialize the form validator
        this.formValidator = new FormValidator('#form1', options);
    }
    
    getAllInformation() {

        // const streets = [];
        // const { shippingCityId, shippingStreetId } = this.state;

        // if (shippingStreetId != "") {
        //     streetsList.forEach(({ StreetId, Name, CityId }) => {
        //         if (CityId === shippingCityId) {
        //             streets.push({ StreetId, Name });
        //         }
        //     });
        // }

        // this.setState({
        //     streets
        // });

        // InformationService.getAll()
        //     .then(response => {
        //         const streets = [];
        //         const streetsAll = response.data.data.streets;
        //         const { shippingCityId, shippingStreetId } = this.state;       

        //         if (shippingStreetId) {
        //             streetsAll.forEach(({ streetId, name, cityId }) => {
        //                 if (cityId === shippingCityId) {
        //                     streets.push({ streetId, name });
        //                 }
        //             });
        //         }

        //         this.setState({
        //             cities: response.data.data.cities,
        //             streetsAll,
        //             streets
        //         });
        //     })
        //     .catch(e => {
        //         console.log(e);
        //     });
    }
    
    handleChange = (e) => {
        const value = e.value;
        const name = e.target.name;

        if (name === 'shippingContent') {
            this.setState({
                ...this.state,
                [name]: value,
                shippingContentLength: value.length
            });
        }
        else {
            this.setState({
                ...this.state,
                [name]: value
            });
        }
    }

    shippingCityChange = (e) => {
        const { streetsAll } = this.state;
        const shippingCityId = e.value;
        
        let streets = [];

        if (streetsAll?.length > 0) {
            streetsAll.forEach(({ StreetId, Name, CityId }) => {
                if (CityId === shippingCityId) {
                    streets.push({ StreetId, Name });
                }
            });
        }
        
        this.setState({ shippingCityId, streets, shippingStreetId: null });
    }

    handleDDLChange = (args) => {
        const value = args.value;
        const name = args.element.id;

        this.setState({
            ...this.state,
            [name]: value
        });
    }

    submitClick = () => {
        if (this.formValidator.validate()) {
            if (localStorage.getItem('order')) {

                let order = JSON.parse(localStorage.getItem('order'));

                const { fullname, email, phone, shippingHome, shippingApartment, shippingZipcode, shippingContent } = this.state;

                const shippingCityId = parseInt(this.state.shippingCityId);
                const shippingStreetId = parseInt(this.state.shippingStreetId);

                order = { ...order,
                    shipping : {
                        fullname,
                        email,
                        phone,
                        shippingCityId,
                        shippingStreetId,
                        shippingHome,
                        shippingApartment,
                        shippingZipcode,
                        shippingContent
                    }
                };

                localStorage.setItem('order', JSON.stringify(order));
                this.props.history.push('/checkout');
            }
            else {
                this.props.history.push('/order');
            }
        }
        else {
            //document.getElementsByTagName("form")[0]?.querySelector("input.e-error")?.focus();
        }
    }

    onOpenCities = (event) => { 
        if (Browser.isDevice) {  
            var parentElement = this.citiesListObj.inputWrapper.container;  
            event.popup.position = { X: "left", Y: "button" };  
            //event.popup.width = parentElement.offsetWidth;
            event.popup.width = '100%';
            event.popup.dataBind();   
            event.popup.element.classList.remove('e-popup-full-page', "e-ddl-device");  
            event.popup.element.style.top = parseInt(event.popup.element.style.top) +  parentElement.offsetHeight + "px";   
            event.popup.element.style.bottom = "auto";  
            event.popup.element.style.maxHeight = this.citiesListObj.popupHeight;  
            event.popup.element.querySelector(".e-content").style.maxHeight = parseInt(this.citiesListObj.popupHeight) - 35 + "px";  
            event.popup.element.querySelector(".e-content").style.height = "initial"; 
        }  
    }; 

    onOpenStreets = (event) => { 
        if (Browser.isDevice) {  
            var parentElement = this.streetsListObj.inputWrapper.container;  
            event.popup.position = { X: "left", Y: "button" };  
            //event.popup.width = parentElement.offsetWidth;
            event.popup.width = '100%';
            event.popup.dataBind();   
            event.popup.element.classList.remove('e-popup-full-page', "e-ddl-device");  
            event.popup.element.style.top = parseInt(event.popup.element.style.top) +  parentElement.offsetHeight + "px";   
            event.popup.element.style.bottom = "auto";  
            event.popup.element.style.maxHeight = this.streetsListObj.popupHeight;  
            event.popup.element.querySelector(".e-content").style.maxHeight = parseInt(this.streetsListObj.popupHeight) - 35 + "px";  
            event.popup.element.querySelector(".e-content").style.height = "initial"; 
        }  
    }; 

    render() {

        const { fullname, email, phone, shippingCityId, shippingStreetId, shippingHome, shippingApartment, shippingZipcode, shippingContent, cities, streets, shippingContentLength } = this.state;

        return (
            <>
                <Helmet> 
                    <title>Baby beep - הזמן עכשיו</title>
                    <meta property="og:title" content="Baby beep - הזמן עכשיו" />
                    <meta name="description" content="ביפ קטן בשבילכם, ביטחון גדול לילדיםעם השלמת הרכישה, תוכלו גם אתם ליהנות מהביטחון המלא שהילדים שלכם שמורים ובטוחים ולא ישכחו ברכב. בחרו כמות וצבע, והכריות בדרך אליכם עם משלוח חינם עד הבית." />
                    <meta property="og:description" content="ביפ קטן בשבילכם, ביטחון גדול לילדיםעם השלמת הרכישה, תוכלו גם אתם ליהנות מהביטחון המלא שהילדים שלכם שמורים ובטוחים ולא ישכחו ברכב. בחרו כמות וצבע, והכריות בדרך אליכם עם משלוח חינם עד הבית." />
                </Helmet>
                
                <form id='form1' autoComplete="off">
                    <div className="container shipping py-5">
                        <div className="row justify-content-center">
                            <div className="col-12 mb-5">
                                <OrderStep
                                    step={2}>
                                </OrderStep>
                            </div>
                        </div>
                        <div className="row">
                            {/* <div className="col-md-7">
                                {this.state.order !== null &&
                                    <ShoppingCart shoppingCart={order.shoppingCart} orderTotal={orderTotal} orderTotalBeforeCoupon={orderTotal} {...this.props}></ShoppingCart>
                                }
                            </div> */}
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-12 mb-2">
                                        <h2>פרטי לקוח</h2>
                                    </div>
                                </div>
                                <div className="row form-group mb-5">
                                    <div className="col-md-6 mb-3">
                                        <TextBoxComponent
                                            id="fullname"
                                            name="fullname"
                                            placeholder="*שם מלא"
                                            value={fullname}
                                            onChange={this.handleChange}
                                            cssClass="e-outline"
                                            floatLabelType="Auto"
                                        />
                                        <label className='e-error' htmlFor='fullname' />
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <TextBoxComponent
                                            id="phone"
                                            name="phone"
                                            placeholder="*טלפון נייד"
                                            type="tel"
                                            value={phone}
                                            onChange={this.handleChange}
                                            cssClass="e-outline"
                                            floatLabelType="Auto"
                                        />
                                        <label className='e-error' htmlFor='phone' />
                                    </div>
                                    <div className="col-12">
                                        <TextBoxComponent
                                            id="email"
                                            name="email"
                                            type="email"
                                            placeholder="*דואר אלקטרוני (לכתובת זו תישלח החשבונית)"
                                            value={email}
                                            onChange={this.handleChange}
                                            cssClass="e-outline"
                                            floatLabelType="Auto"
                                        />
                                        <label className='e-error' htmlFor='email' />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 mb-3">
                                        <h2>סוג משלוח</h2>
                                    </div>
                                </div>
                                <div className="row form-group">
                                    <div className="col-12 mb-3">
                                        <h3><HomeIcon></HomeIcon> שליח עד הבית - ללא תשלום נוסף</h3>
                                        <span className="shipping-type-note">
                                            עד 7 ימי עסקים, בכפוף למדיניות החלוקה של חברת השליחויות ומדיניות משרד הבריאות.
                                        </span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <DropDownListComponent 
                                            id='shippingCityId'
                                            name="shippingCityId"
                                            placeholder="*בחר עיר"
                                            value={shippingCityId}
                                            change={this.shippingCityChange}
                                            ref={(dropdownlist) => { this.citiesListObj = dropdownlist; }} 
                                            dataSource={cities}
                                            fields={{ text: 'Name', value: 'Id' }}
                                            allowFiltering={true}
                                            noRecordsTemplate={(cities === null || cities.length === 0 ? "אנא המתן, טוען רשימת ערים..." :"אין נתונים לתצוגה")}
                                            filterBarPlaceholder='חפש עיר'
                                            cssClass="e-outline"
                                            floatLabelType="Auto"
                                            popupHeight="220px"
                                            enableRtl={true}
                                            open={this.onOpenCities}
                                        />
                                        <label className='e-error' htmlFor='shippingCityId' />
                                    </div>
                                    
                                    <div className="col-md-6 mb-3">
                                        <DropDownListComponent 
                                            id='shippingStreetId'
                                            name="shippingStreetId"
                                            placeholder="*בחר רחוב"
                                            value={shippingStreetId}
                                            change={this.handleDDLChange}
                                            ref={(dropdownlist) => { this.streetsListObj = dropdownlist; }}
                                            dataSource={streets}
                                            fields={{ text: 'Name', value: 'StreetId' }}
                                            allowFiltering={true}
                                            noRecordsTemplate={(streets == null ? "אנא המתן, טוען רשימת רחובות..." :"אין נתונים לתצוגה")}
                                            filterBarPlaceholder='חפש רחוב'
                                            cssClass="e-outline"
                                            floatLabelType="Auto"
                                            popupHeight="250px"
                                            enableRtl={true}
                                            open={this.onOpenStreets}
                                            //readonly={(streets == null)}
                                        />
                                        <label className='e-error' htmlFor='shippingStreetId' />
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <TextBoxComponent 
                                            id="shippingHome"
                                            name="shippingHome"
                                            placeholder="*בית"
                                            onChange={this.handleChange}
                                            value={shippingHome}
                                            cssClass="e-outline"
                                            floatLabelType="Auto"
                                        />
                                        <label className='e-error' htmlFor='shippingHome' />
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <TextBoxComponent 
                                            id="shippingApartment"
                                            name="shippingApartment"
                                            placeholder="דירה"
                                            onChange={this.handleChange}
                                            value={shippingApartment}
                                            cssClass="e-outline"
                                            floatLabelType="Auto"
                                        />
                                        <label className='e-error' htmlFor='shippingApartment' />
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <TextBoxComponent 
                                            id="shippingZipcode"
                                            name="shippingZipcode"
                                            placeholder="מיקוד"
                                            onChange={this.handleChange}
                                            value={shippingZipcode}
                                            cssClass="e-outline"
                                            floatLabelType="Auto"
                                        />
                                        <label className='e-error' htmlFor='shippingZipcode' />
                                    </div>
                                    <div className="col-12">
                                        <TextBoxComponent 
                                            id="shippingContent"
                                            name="shippingContent"
                                            placeholder="הערות למשלוח"
                                            multiline                                    
                                            onChange={this.handleChange}
                                            value={shippingContent}
                                            cssClass="e-outline"
                                            floatLabelType="Auto"
                                            maxLength="500"
                                        />
                                        {shippingContentLength >= 1 && `${shippingContentLength}/500`}
                                        <label className='e-error' htmlFor='shippingContent' />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 text-left">
                                        <ButtonComponent
                                            onClick={this.submitClick}
                                            isPrimary={true}
                                            cssClass="e-btn-bb"
                                            type='button'
                                        >
                                            המשך לתשלום
                                        </ButtonComponent>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </>
        );
    }
}