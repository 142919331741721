import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default class End extends Component {
    constructor(props) {
        super(props);

        this.state = {
            orderId: ''
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)

        if (this.props.location.state !== undefined && this.props.location.state['orderId'] !== undefined) {
            this.setState({
                orderId: this.props.location.state['orderId']
            });
        }
    }

    render() {

        const { orderId } = this.state;

        return (
            <>
                <Helmet> 
                    <title>Baby beep - הזמן עכשיו</title>
                    <meta property="og:title" content="Baby beep - הזמן עכשיו" />
                    <meta name="description" content="ביפ קטן בשבילכם, ביטחון גדול לילדיםעם השלמת הרכישה, תוכלו גם אתם ליהנות מהביטחון המלא שהילדים שלכם שמורים ובטוחים ולא ישכחו ברכב. בחרו כמות וצבע, והכריות בדרך אליכם עם משלוח חינם עד הבית." />
                    <meta property="og:description" content="ביפ קטן בשבילכם, ביטחון גדול לילדיםעם השלמת הרכישה, תוכלו גם אתם ליהנות מהביטחון המלא שהילדים שלכם שמורים ובטוחים ולא ישכחו ברכב. בחרו כמות וצבע, והכריות בדרך אליכם עם משלוח חינם עד הבית." />
                </Helmet>
                
                <div className="container end py-5 d-flex flex-column justify-content-center">
                    <div className="row">
                        <div className="col-12 text-center mb-4">
                            <img src="https://static.babybeep.co.il/images/logoend.png" className="img-fluid" alt="לוגו Baby beep"></img>   
                        </div>
                    </div>
                    {/* <div className="row">
                        <div className="col text-center">
                            <div className="end-warranty mx-auto px-5">
                                <Link to="/warranty">
                                    <div className="end-warranty-first">הרחיבו את האחריות לשנתיים</div>
                                    <div className="end-warranty-second">ב-59 ₪ בלבד</div>
                                    <div className="end-warranty-third">עד 3 ימים מיום הרכישה</div>
                                </Link>
                            </div>
                        </div>
                    </div> */}
                    <div className="row">
                        <div className="col-12 text-center mb-5">
                            <h1>תהליך הרכישה הסתיים בהצלחה</h1>
                            <p>
                                אנו מודים לך על רכישת מערכת Baby beep ומאחלים רק בריאות!
                            </p>
                            <p>
                                בימים הקרובים תקבל מסרון מחברת השליחויות להמשך מעקב ופרטים אחר המשלוח.
                            </p>
                            <p>
                                <a href="https://www.bar123.co.il/ords/f?p=106" target="_blank" rel="noopener noreferrer" aria-label="קישור לפרטי מעקב אחר המשלוח">לחץ כאן למעקב אחר משלוח Baby beep</a>
                                <br></br>
                                <strong>מספר המשלוח אותו יש להזין הינו מספר ההזמנה המופיע מטה</strong>
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 text-center">
                            <div className="end-order-number mx-auto">
                                מספר הזמנה: {orderId}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}