import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { TextBoxComponent, FormValidator } from '@syncfusion/ej2-react-inputs';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { ButtonComponent, CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import WarrantyService from '../services/warranty.service';
import PulseLoader from "react-spinners/PulseLoader";
import ModalMessage from '../components/modalMessage'
import { FaCcVisa, FaCcMastercard, FaCcDinersClub } from 'react-icons/fa';

export default class Checkout extends Component {
    constructor(props) {
        super(props);

        const monthes = [
            { code: '01', name: '01' },
            { code: '02', name: '02' },
            { code: '03', name: '03' },
            { code: '04', name: '04' },
            { code: '05', name: '05' },
            { code: '06', name: '06' },
            { code: '07', name: '07' },
            { code: '08', name: '08' },
            { code: '09', name: '09' },
            { code: '10', name: '10' },
            { code: '11', name: '11' },
            { code: '12', name: '12' },
        ];

        const years = [];
        const year = parseInt(new Date().getFullYear().toString().slice(-2));
        Array.from( new Array(10), (v,i) =>
            years.push({code: ((year+i).toString()), name: (year+i).toString()})
        );

        this.state = {
            fullname: '',
            email: '',
            phone: '',
            orderId: '',
            serialNumber: '',
            cardName: '',
            cardIdNumber: '',
            cardNumber: '',
            cardExpirationMonth: '',
            cardExpirationYear: '',
            cardCvv2: '',
            modalShow: false,
            monthes,
            years,
            btnLoader: false
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0)

        const options = {
            rules: {
                'fullname': {
                    required: [true, 'שדה חובה'],
                    maxLength: [50, 'מקסימום {0} תווים']
                },
                'email': {
                    required: [true, 'שדה חובה'],
                    email: [true, 'אימייל לא חוקי'],
                    maxLength: [256, 'מקסימום {0} תווים']
                },
                'phone': {
                    required: [true, 'שדה חובה'],
                    number: [true, 'מספר טלפון לא תקני'],
                    minLength: [8, 'מספר טלפון לא תקני'],
                    maxLength: [20, 'מקסימום {0} תווים']
                },
                'orderId': {
                    required: [true, 'שדה חובה'],
                    maxLength: [10, 'מקסימום {0} תווים']
                },
                'serialNumber': {
                    required: [true, 'שדה חובה'],
                    maxLength: [10, 'מקסימום {0} תווים']
                },
                'cardName': {
                    required: [true, 'שדה חובה'],
                    maxLength: [50, 'מקסימום {0} תווים']
                },
                'cardIdNumber': {
                    required: [true, 'שדה חובה'],
                    maxLength: [9, 'מקסימום {0} תווים'],
                    number: [true, 'מספרים בלבד'],
                    regex: [this.validateId, 'מספר תעודת זהות לא חוקי']
                },
                'cardNumber': {
                    required: [true, 'שדה חובה'],
                    number: [true, 'מספרים בלבד'],
                    regex: [this.validateCreditCardnumber, 'מספר כרטיס אשראי לא חוקי']
                },
                'cardExpirationMonth': {
                    required: [true, 'שדה חובה']
                },
                'cardExpirationYear': {
                    required: [true, 'שדה חובה']
                },
                'cardCvv2': {
                    required: [true, 'שדה חובה'],
                    number: [true, 'מספרים בלבד'],
                    minLength: [3, 'מינימום {0} תווים'],
                    maxLength: [4, 'מקסימום {0} תווים']
                },
                'confirm': {                    
                    required: [true, 'חובה לאשר קריאת תנאי השימוש'],
                }
            }
        }

        // initialize the form validator
        this.formValidator = new FormValidator('#form1', options);
    }
    
    handleChange = (e) => {
        const value = e.value;
        const name = e.target.name;                

        this.setState({
            ...this.state,
            [name]: value
        });
    }

    handleCheck = (args) => {
        const value = args.checked;
        const name = args.event.target.name;

        this.setState({
            ...this.state,
            [name]: value
        });
    }

    handleDDLChange = (args) => {
        const value = args.value;
        const name = args.element.id;

        this.setState({
            ...this.state,
            [name]: value
        });
    }

    submitClick = () => {

        debugger;
        const { serialNumber, orderId } = this.state;
        
        if (serialNumber !== '') {
            this.formValidator.addRules('serialNumber', {
                required: [true, 'שדה חובה'],
                maxLength: [10, 'מקסימום {0} תווים']
            });
            this.formValidator.removeRules('orderId');
        }
        else {
            //this.formValidator.removeRules('serialNumber');
        }

        if (orderId !== '') {
            this.formValidator.addRules('orderId', {
                required: [true, 'שדה חובה'],
                maxLength: [10, 'מקסימום {0} תווים']
            });
            this.formValidator.removeRules('serialNumber');
        }
        else {
            //this.formValidator.removeRules('orderId');
        }

        if (this.formValidator.validate()) {
            this.setState({
                btnLoader: true
            });

            const { cardName, cardIdNumber, cardNumber, cardExpirationMonth, cardExpirationYear, cardCvv2, confirm, fullname, email, phone, serialNumber, orderId } = this.state;

            const warranty = {
                fullname,
                email,
                phone,
                cardName,
                cardIdNumber,
                cardNumber,
                cardExpirationMonth,
                cardExpirationYear,
                cardCvv2,
                confirmTerms: confirm,
                serialNumber,
                orderId
            };

            WarrantyService.create(warranty)
                .then(response => {
                    if (response.data.status === 'success')
                    {
                        this.setState({
                            modalHeader: 'הרחבת האחריות הסתיימה בהצלחה!',
                            modalBody: '<p>אנו מודים לך על רכישת האחריות לשנה נוספת, אנא שמור על החשבונית שנשלחה אליך בדואר האלקטרוני אותו הזנת בתהליך הרחבת האחריות.</p><p>צוות Baby beep</p>',
                            modalShow: true,
                            btnLoader: false,
                            fullname: '',
                            email: '',
                            phone: '',
                            cardName: '',
                            cardIdNumber: '',
                            cardNumber: '',
                            cardExpirationMonth: null,
                            cardExpirationYear: null,
                            cardCvv2: '',
                            confirm: false,
                            serialNumber: '',
                            orderId: ''
                        });
                    }
                    else {
                        this.setState({
                            modalHeader: 'אופס...',
                            modalBody: 'ארעה שגיאה בתהליך הרכישה, בדוק שכל הנתונים שהזנת אכן תקינים ונכונים.',
                            modalShow: true,
                            btnLoader: false
                        });
                    }
                })
                .catch(e => {
                    this.setState({
                        modalHeader: 'אופס...',
                        modalBody: 'ארעה שגיאה בתהליך הרכישה, בדוק שכל הנתונים שהזנת אכן תקינים ונכונים.',
                        modalShow: true,
                        btnLoader: false
                    });
                });
        }
    }

    modalClose = () => {
        this.setState({
            modalShow: false
        })
    }

    validateCreditCardnumber(cardNo) {      
        if (cardNo === null || cardNo === '' || cardNo === undefined)
        {
            return false;
        }

        var s = 0;
        var doubleDigit = false;
        for (var i = cardNo.length - 1; i >= 0; i--) {
            var digit = +cardNo[i];
            if (doubleDigit) {
                digit *= 2;
                if (digit > 9)
                    digit -= 9;
            }
            s += digit;
            doubleDigit = !doubleDigit;
        }
        return s % 10 === 0;
    };

    validateId = (args) => {
        let strId = String(args.value).trim();
        if (strId.length > 9) {
           return false;
        }
        if (strId.length < 9) {
           while (strId.length < 9) strId = "0" + strId;
        }
        let counter = 0, rawVal, actualVal;
        for (let i = 0; i < strId.length; i++) {
           rawVal = Number(strId[i]) * ((i % 2) + 1);
           actualVal = rawVal > 9 ? (rawVal - 9) : rawVal;
           counter += actualVal;
        }
        return (counter % 10 === 0);
     };

    render() {
        const { cardName, cardIdNumber, cardNumber, cardExpirationMonth, cardExpirationYear, cardCvv2, 
            confirm, monthes, years, btnLoader, modalShow, modalBody, modalHeader,
            fullname, email, phone, orderId, serialNumber } = this.state;

        return (
            <>
                <Helmet>                   
                    <title>Baby beep - הרחבת אחריות</title>
                    <meta property="og:title" content="Baby beep - הרחבת אחריות" />
                    <meta name="description" content="הרחבת אחריות לשנה נוספת ב-59 ₪ בלבד!" />
                    <meta property="og:description" content="הרחבת אחריות לשנה נוספת ב-59 ₪ בלבד!" />                    
                </Helmet>

                <form id='form1' autoComplete="off">
                    <div className="container checkout py-5">
                        <div className="row">
                            <div className="col text-center">
                                <div className="end-warranty mx-auto px-5">
                                    <div className="end-warranty-first">הרחיבו את האחריות לשנתיים</div>
                                    <div className="end-warranty-second">ב-59 ₪ בלבד</div>
                                    <div className="end-warranty-third">עד 3 ימים מיום הרכישה</div>                                    
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col mb-2">
                                <h1>הרחבת אחריות לשנה נוספת (סה"כ שנתיים)</h1>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col mb-2">
                                <p className="text-justify">
                                    לאחר סיום תהליך התשלום בעמוד זה, מוצר Baby beep עבורו תרכשו את הרחבת האחריות יהיה באחריות מלאה לשנה נוספת (סה״כ שנתיים אחריות מלאה) על פי כללי האחריות <a rel="noopener noreferrer" href="https://static.babybeep.co.il/pdf/babybeep_manual_heb_v1.pdf" target="_blank">במדריך למשתמש</a> סעיף 1.5.
                                    <br></br>
                                    במידה ורכשת הרחבת אחריות במועד בו רכשת את המוצר ו\או עדיין לא קיבלת את המוצר והוא אינו מצוי בידך, כך שאין בידך את המספר הסריאלי הרשום בתווית המחוברת למוצר, אנא מלא את מספר ההזמנה שקיבלת בסיום תהליך הרכישה.
                                    <br></br>
                                    במידה והמוצר נרכש והינו מצוי בידך, הנך יכול למלא בעמוד זה את המספר הסריאלי (ללא מספר הזמנה) הנמצא על התווית הלבנה המוצמדת לכרית ה-Baby beep, להסבר ואיור מפורט עבור <a rel="noopener noreferrer" href="https://static.babybeep.co.il/pdf/babybeep_manual_heb_v1.pdf" target="_blank">למדריך למשתמש</a> עמוד 2.
                                    <br></br>
                                    במידה ומעוניינים לרכוש הרחבת אחריות עבור יותר ממוצר אחד, יש לבצע רכישה עבור כל כרית Baby beep בנפרד, כך שכל כרית תהיה בעלת תעודת אחריות נפרדת.
                                </p>
                                <p>
                                    לאחר סיום תהליך רכישת האחריות, החשבונית תשלח לכתובת הדואר האלקטרוני שתוזן כאן בתהליך הרכישה ותשמש כאחריות למוצר, בכל פניה להפעלת האחריות תדרשו להציג חשבונית רכישת מוצר וחשבונית אחריות.
                                </p>
                                <p>
                                    בכל שאלה הנכם מוזמנים לפנות אלינו באמצעות דרכי ההתקשרות המצוינות בעמוד <Link to="/contact">צור קשר</Link>.
                                </p>
                            </div>
                        </div>
                        <div className="row form-group mb-5">
                            <div className="col-12 col-lg-6 form-group">
                                <TextBoxComponent
                                    id="fullname"
                                    name="fullname"
                                    placeholder="*שם מלא"
                                    value={fullname}
                                    onChange={this.handleChange}
                                    cssClass="e-outline"
                                    floatLabelType="Auto"
                                />
                                <label className='e-error' htmlFor='fullname' />
                            </div>
                            <div className="col-12 col-lg-6 form-group">
                                <TextBoxComponent
                                    id="phone"
                                    name="phone"
                                    placeholder="*טלפון נייד"
                                    type="tel"
                                    value={phone}
                                    onChange={this.handleChange}
                                    cssClass="e-outline"
                                    floatLabelType="Auto"
                                />
                                <label className='e-error' htmlFor='phone' />
                            </div>
                            <div className="col-12 col-lg-6 form-group">
                                <TextBoxComponent
                                    id="email"
                                    name="email"
                                    type="email"
                                    placeholder="*דואר אלקטרוני (לכתובת זו תישלח החשבונית)"
                                    value={email}
                                    onChange={this.handleChange}
                                    cssClass="e-outline"
                                    floatLabelType="Auto"
                                />
                                <label className='e-error' htmlFor='email' />
                            </div>
                            {serialNumber === '' &&
                                <div className={`${orderId !== '' ? 'col-lg-6' : 'col-lg-3'} col-12 form-group serial-order-number`}>
                                        <TextBoxComponent
                                            id="orderId"
                                            name="orderId"
                                            type="orderId"
                                            placeholder="*מספר הזמנה"
                                            value={orderId}
                                            onChange={this.handleChange}
                                            cssClass="e-outline"
                                            floatLabelType="Auto"
                                        />
                                        <label className='e-error' htmlFor='orderId' />
                                </div>
                            }
                            {orderId === '' &&
                                <div className={`${serialNumber !== '' ? 'col-lg-6' : 'col-lg-3'} col-12 form-group serial-order-number`}>
                                    <TextBoxComponent
                                        id="serialNumber"
                                        name="serialNumber"
                                        type="serialNumber"
                                        placeholder="*מספר סריאלי"
                                        value={serialNumber}
                                        onChange={this.handleChange}
                                        cssClass="e-outline"
                                        floatLabelType="Auto"
                                    />
                                    <label className='e-error' htmlFor='serialNumber' />
                                </div>
                            }
                        </div>
                        <div className="row">
                            <div className="col mb-2">
                                <h2>פרטי כרטיס האשראי</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-lg-6 form-group">
                                <TextBoxComponent
                                    id="cardName"
                                    name="cardName"
                                    placeholder="*שם בעל הכרטיס"
                                    value={cardName}
                                    onChange={this.handleChange}
                                    cssClass="e-outline"
                                    floatLabelType="Auto"
                                />
                                <label className='e-error' htmlFor='cardName' />
                            </div>
                            <div className="col-12 col-lg-6 form-group">
                                <TextBoxComponent
                                    id="cardIdNumber"
                                    name="cardIdNumber"
                                    placeholder="*תעודת זהות"
                                    type="number"
                                    value={cardIdNumber}
                                    onChange={this.handleChange}
                                    cssClass="e-outline"
                                    floatLabelType="Auto"
                                />
                                <label className='e-error' htmlFor='cardIdNumber' />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col cc-icons">
                                <FaCcVisa className="ml-2"></FaCcVisa>
                                <FaCcMastercard className="ml-2"></FaCcMastercard>
                                <FaCcDinersClub></FaCcDinersClub>
                            </div>
                        </div>
                        <div className="row form-group">
                            <div className="col-12">
                                <TextBoxComponent
                                    id="cardNumber"
                                    name="cardNumber"
                                    placeholder="*מספר כרטיס אשראי"
                                    type="number"
                                    value={cardNumber}
                                    onChange={this.handleChange}
                                    cssClass="e-outline"
                                    floatLabelType="Auto"
                                />
                                <label className='e-error' htmlFor='cardNumber' />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 col-lg-4 form-group">
                                <DropDownListComponent 
                                    id='cardExpirationMonth'
                                    name="cardExpirationMonth"
                                    placeholder="*בחר חודש"
                                    value={cardExpirationMonth}
                                    change={this.handleDDLChange}
                                    dataSource={monthes}
                                    fields={{ text: 'name', value: 'code' }}                                                    
                                    noRecordsTemplate={"אין נתונים לתצוגה"}
                                    cssClass="e-outline"
                                    floatLabelType="Auto"
                                    enableRtl={true}
                                />
                                <label className='e-error' htmlFor='cardExpirationMonth' />
                            </div>
                            <div className="col-6 col-lg-4 form-group">
                                <DropDownListComponent 
                                    id='cardExpirationYear'
                                    name="cardExpirationYear"
                                    placeholder="*בחר שנה"
                                    value={cardExpirationYear}
                                    change={this.handleDDLChange}
                                    dataSource={years}
                                    fields={{ text: 'name', value: 'code' }}                                                    
                                    noRecordsTemplate={"אין נתונים לתצוגה"}
                                    cssClass="e-outline"
                                    floatLabelType="Auto"
                                    enableRtl={true}
                                />
                                <label className='e-error' htmlFor='cardExpirationYear' />
                            </div>
                            <div className="col-6 col-lg-4 form-group">
                                <TextBoxComponent
                                    id="cardCvv2"
                                    name="cardCvv2"
                                    placeholder="*ספרות בגב הכרטיס"
                                    type="number"
                                    value={cardCvv2}
                                    onChange={this.handleChange}
                                    cssClass="e-outline"
                                    floatLabelType="Auto"
                                />
                                <label className='e-error' htmlFor='cardCvv2' />
                            </div>
                        </div>
                        <div className="row form-group">
                            <div className="col">
                                <CheckBoxComponent
                                    id="confirm"
                                    name="confirm"
                                    label="אני מאשר כי קראתי את <a href='/terms' target='_blank'>תנאי השימוש</a> באתר."
                                    change={this.handleCheck}
                                    checked={confirm}
                                >
                                </CheckBoxComponent>
                                <label className='e-error' htmlFor='confirm'/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col text-left">
                                <span className="font-weight-bold ml-3">סך הכל לתשלום: 59 ₪</span>
                                <ButtonComponent
                                    onClick={this.submitClick}
                                    isPrimary={true}
                                    cssClass="e-btn-bb"
                                    type='button'
                                >
                                    {btnLoader ? <PulseLoader color={'#fff'} loading={btnLoader} size={10} /> : "בצע רכישה"}
                                </ButtonComponent>
                            </div>
                        </div>
                    </div>
                    <ModalMessage visible={modalShow} close={this.modalClose} content={modalBody} header={modalHeader}></ModalMessage>
                </form>
            </>
        );
    }
}