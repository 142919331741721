import React, { Component } from "react";
import { Helmet } from "react-helmet";

export default class Terms extends Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
    return (
        <>
            <Helmet> 
                <title>Baby beep - תנאי שימוש באתר</title>
                <meta property="og:title" content="Baby beep - תנאי שימוש באתר" />
                <meta name="description" content="אתר babybeep.co.il מוצר נוחות למניעת שכיחת ילדים ברכב פרטי (להלן: “האתר“) הינו בבעלות חברת Team6teach  ומופעל על ידה. האתר משמש כאתר למכירת מוצר נוחות למניעת שכיחת ילדים ברכב פרטי, מוצרים נלווים והרחבת אחריות למוצר לגולשים באתר (להלן “המשתמש/ים” ו/או “הרוכש/ים” ו/או “המזמין“)." />
                <meta property="og:description" content="אתר babybeep.co.il מוצר נוחות למניעת שכיחת ילדים ברכב פרטי (להלן: “האתר“) הינו בבעלות חברת Team6teach  ומופעל על ידה. האתר משמש כאתר למכירת מוצר נוחות למניעת שכיחת ילדים ברכב פרטי, מוצרים נלווים והרחבת אחריות למוצר לגולשים באתר (להלן “המשתמש/ים” ו/או “הרוכש/ים” ו/או “המזמין“)." />
            </Helmet>

            <div className="container terms py-10">
                <div className="row">
                    <div className="col-8 mb-2">
                        <h1>תנאי שימוש באתר</h1>
                    </div>
                </div>
                <div className="row mb-2">                
                    <div className="col-md-12 text-justify">
                        <h2>כללי</h2>
                        <p>
                            1.	אתר babybeep.co.il מוצר נוחות למניעת שכיחת ילדים ברכב פרטי (להלן: “האתר“) הינו בבעלות חברת Team6teach  ומופעל על ידה. האתר משמש כאתר למכירת מוצר נוחות למניעת שכיחת ילדים ברכב פרטי, מוצרים נלווים והרחבת אחריות למוצר לגולשים באתר (להלן “המשתמש/ים” ו/או “הרוכש/ים” ו/או “המזמין“).
                        </p>
                        <p>
                            2.	הוראות תקנון זה יחולו על כל שימוש ו/או רכישה של מוצרים על ידי המשתמשים באתר, והחברה שומרת לעצמה את הזכות לשנות את הוראות התקנון בכל עת, על פי שיקול דעתה הבלעדי של החברה. אשר על כן, טרם ביצוע פעולה כלשהי באתר, מתבקש המשתמש לקרוא, בעיון רב, את תנאי השימוש, לרבות מדיניות הפרטיות.
                        </p>
                        <p>
                            3.	כל רכישה באתר מהווה הסכמת המשתמש לתנאי התקנון.
                        </p>
                        <p>
                            4.	החברה שומרת לעצמה את הזכות לשנות, למחוק ו/או להוסיף על תנאי שימוש אלו בכל עת וללא התחייבות למתן הודעה מוקדמת ו/או בדיעבד. כל תוספת ו/או שינוי שנעשה באתר, בתוכן האתר ו/או בתנאים אלו יחייבו את המשתמש עם המשך שימושו בו. לפיכך, אנו ממליצים למשתמש לעיין בתנאים אלו מעת לעת. שימושך באתר לאחר ביצוע השינויים מעיד על הסכמת המשתמש לשינויים אלו. ככל שאינך מסכים לשינויים אנא הימנע מהמשך שימוש באתר או פנה אלינו לסיוע כדי להעניק לך פתרונות חלופיים.
                        </p>
                        <p>
                            5.	נוסח התקנון המחייב והקובע בכל מועד הינו נוסח התקנון המפורסם באתר.
                        </p>
                        <p>
                            6.	בכל מקרה של סתירה ו/או אי התאמה, מכל מין וסוג שהוא בין תוכן אתר Baby beep לבין תנאי השימוש, התקנון ומדניות הפרטיות, האחרונים יגברו על המידע באתר
                        </p>
                        <p>
                            7.	תוקף תנאי השימוש באתר Baby beep הינו מצטבר ולא לחילופין ותנאי השימוש יפורשו כמתקיימים זה לצד זה ולא מצמצמים זה את זה.
                        </p>
                        <p>
                            8.	שימוש באתר, לרבות גלישה ו/או השתתפות במכירות ו/או רכישת מוצרים על ידי המשתמש, מהווה אישור כי המשתמש קרא את הוראות תנאי השימוש, תקנון ומדיניות פרטיות אתר Baby beep וכן כי הוא מסכים להוראות כל אלו, המעודכנים מעת לעת, וכן מסכים לפעול לפיהן ולקבלן ללא כל הגבלה או הסתייגות. אם אינך מסכים להם, אנא הימנע משימוש באתר או פנה אלינו כדי שנוכל לסייע לך בדרכים חלופיות לשביעות רצונך.
                        </p>
                        <p>
                            9.	מובהר בזאת, האמור בתקנון זה מנוסח מטעמי נוחות בלשון זכר, אולם הוא מתייחס לשני המינים בצורה שווה.
                        </p>
                        <p>
                            10.	המשתמש רשאי לעשות שימוש באתר לצרכים אישיים ולא מסחריים בלבד. 
                        </p>
                        <p>
                            11.	השימוש במידע אותו מסר המשתמש לחברה ו/או השימוש במידע שהצטבר אודות המשתמש בעת השימוש באתר, יתבצע בהתאם להוראות כל דין ובהתאם למדיניות הפרטיות של האתר, המהווה חלק בלתי נפרד מתנאי השימוש.
                        </p>
                        
                        <h2>שימוש אסור באתר החברה</h2>
                        <p>
                            12.	ללא מתן הרשאה מראש ובכתב על-ידי החברה, אינך רשאי לבצע את הפעולות ו/או המעשים הבאים בעת שימושך באתר ובתוכן האתר:
                            <ul>
                                <li>להעתיק, לשחזר, לשנות, לעבד, לתרגם, לבצע הנדסה חוזרת, להפיץ, לשדר, להציג, לבצע, לשכפל, לפרסם ולאחסן את תוכן האתר, כולו או חלקו.</li>
                                <li>לעשות שימוש מסחרי באתר ו/או בתוכן האתר.</li>
                                <li>להפעיל או לאפשר הפעלת כל יישום מחשב או כל אמצעי אחר, לרבות תוכנות מסוג Crawlers, Robots  וכדומה, לשם חיפוש, סריקה, העתקה או אחזור אוטומטי של תוכן האתר.</li>
                                <li>להציג תוכן מהאתר בתוך מסגרת (iframe) גלויה או סמויה או להציג את תוכן האתר בכל דרך שהיא, לרבות באמצעות כל תוכנה, מכשיר, אביזר או פרוטוקול תקשורת, המשנים את עיצובם המקורי באתר ו/או מחסירים דבר כלשהוא</li>
                                <li>לשבש או להפר כל זכות של משתמש אחר באתר, לרבות הזכות לפרטיות ו/או לאסוף מידע אישי על המשתמשים באתר ללא הסכמתם המפורשת בכתב, לרבות באמצעים אוטומטיים.</li>
                                <li>לפגוע בכבודו או בפרטיותו של משתמש אחר ו/או להשתמש באתר ו/או בתוכן האתר כדי לפגוע בשמו הטוב של כל אדם ו/או לפרסם דברי הסתה, הונאה, תרמית, לשון הרע ו/או כל מידע אחר שהינו שקרי, בלתי אמין או שיש בו כדי לפגוע במזיד.</li>
                                <li>שימוש באתר או בתוכן האתר לשם יצירת מאגר מידע ו/או לקט.</li>
                            </ul>
                        </p>
                        <p>
                            13.	אי עמידה במגבלות אלו באתר Baby beep עלולה להוביל למניעת גישתך לאתר ואף לחשוף אותך לאחריות אזרחית ו/או פלילית, על-פי כל דין.
                        </p>

                        <h2>המוצרים המופיעים באתר ואופן מכירתם</h2>
                        <p>
                            14.	מובהר בזאת כי החברה רשאית בכל עת ועל פי שיקול דעתה הבלעדי להוסיף ו/או לגרוע מוצרים המוצעים למכירה באתר babybeep.co.il  וכן לקבוע את אופן מכירתם ואת מחירם, לרבות דמי משלוח.
                        </p>

                        <h2>רכישת מוצרים באתר</h2>
                        <p>
                            15.	אתר babybeep.co.il מציע מוצר נוחות למניעת שכיחת ילדים ברכב פרטי, הרחבת אחריות ומוצרים נלווים המשתנים מעת לעת
                        </p>
                        <p>
                            16.	צוות babybeep.co.il של החברה נותן תשומת לב יתרה ויעשה כל שביכולתו לעדכן ולתחזק את האתר כדי למנוע טעויות בפרסום מוצרים ומחירם.
                        </p>
                        <p>
                            17.	תמונות המוצרים באתר עשויות לעיתים להיות להמחשה בלבד ולא להיות זהים בצבעים או בתחושת המוצר.
                        </p>
                        <p>
                            18.	החברה שומרת לעצמה את הזכות לשנות את מחירי המוצרים בכל עת ללא הודעה מוקדמת.
                        </p>
                        <p>
                            19.	צוות babybeep.co.il של החברה עושה כל שביכולתו ואת מירב המאמצים על מנת לשרת את קהל לקוחותיו ביעילות תוך שימת דגש על שביעות רצונם.
                        </p>
                        <p>
                            20.	לאחר הזמנת מוצר באתר,הלקוח יקבל מסרון והחשבונית תישלח במייל בלבד(הזנת מייל(דואר אלקטרוני פעיל ותקין) באתר babybeep.co.il הינו חובה), במידה והלקוח מעוניין לקבל חשבונית בדרך נוספת עליו ליצור קשר עם צוות האתר בטלפון המפורסם באתר ולבקש זאת בנפרד
                        </p>
                        <p>
                            21.	השלמת הליך העסקה מותנה לפעמים באישור ואימות טלפוני של נציג שירות הלקוחות של החברה מול המזמין לאחר ביצוע ההזמנה, וזאת על מנת למנוע טעויות הן בזהות המזמין והן בפרטי האשראי, ושאר פרטי המזמין.
                        </p>
                        <p>
                            22.	במקרה שהעסקה לא אושרה ע”י חברת האשראי יקבל מבצע ההזמנה הודעה לאחד מאמצעי ההתקשרות שמסר המזמין בעת ביצוע ההזמנה, והחברה תהיה רשאית לראות עסקה זו כמבוטלת.
                        </p>
                        <p>
                            23.	ספירת הימים למועד המשלוח המצוין באתר יחול יום לאחר קבלת אישור העסקה ע”י חברת האשראי, יש לעקוב באתר babybeep.co.il אחר התחייבות זמני המשלוח, יחד עם זאת יש לקחת בחשבון התכנות לאיחורים במשלוח משום שחברה חיצונית אחראית למשלוחים עד הבית, חברת, צוות babybeep.co.il, מעביר את ההזמנה שבוצעה באתר בצורה מידית דרך ממשק משותף לחברת השליחויות, אך יתכנו כשלים טכנולוגים היכולים להוות מכשול לזמנים אותם אנו מצפים לראות, כגון כשלי תקשורת, כשלי שרתים בכל שדירת המעבר וכן כשלים נוספים אשר אין ביכולתנו לצפות מראש ואותם יש לקחת בחשבון
                        </p>
                        <p>
                            24.	המחירים המופיעים באתר כוללים מע”מ כחוק.
                        </p>
                        <p>
                            25.	לפרטים ובירורים נוספים ניתן לפנות לשירות הלקוחות של החברה בטלפון, בוואטסאפ ובצור קשר באתר האינטרנט ובאפליקציה.
                        </p>
                        <p>
                            26.	babybeep.co.il משתמש בחברת סליקה חיצונית ומאובטחת(טרנזילה(https://www.tranzila.com)), כל תהליך הכנסת פרטי האשראי מתבצע מבחינה טכנולוגית תחת אתר טרנזילה המאובטח ומאורגן על ידה עם התקנים האבטחתיים אשר ברשותה, ניתן לצפות באתר טרנזילה(https://www.tranzila.com) להבנה עמוקה יותר של תהליך האבטחה, השימוש באתר שלנו נעשה בעזרת SSL מאובטח ותחת פתרונות טרנזילה.
                        </p>

                        <h2>פרטי המשתמש באתר</h2>
                        <p>
                            29.	בעת רכישת מוצר באתר ייתכן והרוכש יידרש למסור למערכת את הפרטים הבאים: שם מלא, כתובת, דואר אלקטרוני, מספרי טלפון, מס’ כרטיס אשראי, תוקף וכד’ וכל פרט אחר אשר נדרש למוסרו לטובת הרכיש. כדי שההזמנה תבוצע במהירות וללא תקלות יש להקפיד על מסירת הפרטים הנכונים אחרת לא תוכל החברה להבטיח את ביצוע הרכישה.
                        </p>
                        <p>
                            30.	מובהר בזאת, כי הקלדת פרטים אישיים כוזבים אסורה לחלוטין ומהווה עבירה פלילית על פי הוראות חוק העונשין והעושה כן צפוי להליכים משפטיים, פליליים ואזרחיים, לרבות תביעות נזיקין בגין נזקים שיגרמו לחברה עקב שיבוש הליכי המכירה.
                        </p>
                        
                        <h2>הזכאים לעשות שימוש בשירותי האתר</h2>
                        <p>
                            31.	רשאים להשתתף במכירות המבוצעות באמצעות אתר זה משתמשים אשר עומדים בתנאים המצטברים המפורטים להלן:
                            <ul>
                                <li>משתמשים בני 18 ומעלה בעלי תעודת זהות ישראלית תקפה או תאגיד המאוגד והרשום כדין בישראל.</li>
                                <li>בכל מקרה חוקי המשתמש יעשה שימוש באתר בהתאם להוראות תנאי שימוש זה, התקנון ומדיניות הפרטיות באתר.</li>
                            </ul>
                        </p>
                        <p>
                            32.	למען הסר ספק מובהר כי לחברה נתונה הרשות בכל עת ועל פי שיקול דעתה הבלעדי למנוע ממשתמשים את הגישה למכירות ו/או לבטל את השתתפותם, היה והתנהגותם אינה הולמת ו/או היה והם מבצעים שימוש בלתי ראוי או בלתי סביר באתר, לרבות אי ציות לתנאי השימוש ו/או למדיניות הפרטיות ו/או אם כרטיס האשראי של המשתמש נחסם או הוגבל לשימוש.
                        </p>

                        <h2>ביטול עסקאות והשבת מוצרים</h2>
                        <p>
                            33.	כל הרוכש מוצר באתר זה רשאי לבטל את העסקה בהתאם להוראות חוק הגנת הצרכן, הרוכש מחויב לבדוק את המוצר מיד עם קבלתו.
                        </p>
                        <p>
                            34.	רוכש שביצע עסקה לרכישת מוצר באמצעות האתר, רשאי לבטלה באמצעות הודעה בדבר ביטול העסקה לחברה, החל מיום עשיית העסקה ועד 14 ימים מיום קבלת המוצר או מיום קבלת אישור ביצוע העסקה בכתב המכיל את עיקר תנאי העסקה כנדרש על פי חוק, לפי המאוחר מבניהם, והכל בהתאם להוראות החוק, ובלבד שההתקשרות בעסקה כללה שיחה בין העוסק לרוכש בטלפון המתפרסם באתר, בכל מקרה של החזרת מוצר, המוצר יהיה סגור באריזתו המקורית וכן לא נעשה בו כל שימוש.
                        </p>
                        <p>
                            35.	הודעת ביטול עסקה, לצורך מימוש זכות לביטול עסקה על פי הוראות החוק תעשה באמצעות משלוח הודעת ביטול לחברה באחת מהדרכים הקבועות בחוק.
                        </p>
                        <p>
                            36.	בביטול עסקה או החזרת מוצר עקב פגם או אי התאמה במוצר; או עקב אי אספקה במועד שנקבע בעסקה; או עקב כל הפרה אחרת של תנאי העסקה, תחזיר החברה בתוך 14 ימים מיום קבלת הודעת הביטול, את אותו חלק ממחיר העסקה ששולם, תבטל את החיוב בשל העסקה, תמסור עותק מהודעת ביטול החיוב ולא תגבה מהלקוח סכום כלשהו. המשתמש יעמיד את המוצר וכל נכס אחר שקיבל בעקבות עשיית העסקה  לרשות החברה במקום שבו נמסר לו ויודיע על כך לחברה, רירת המחדל היא לשלוח את המוצר לת״א דואר 11283, מיקוד 91112 ירושלים.
                        </p>
                        <p>
                            37.	בביטול עסקה או החזרת מוצר שלא עקב(1) פגם או אי התאמה במוצר; או (2) אי אספקה במועד שנקבע בעסקה; או (3) כל הפרה אחרת של תנאי העסקה- תחזיר החברה בתוך 14 ימים מיום קבלת הודעת הביטול, את אותו חלק ממחיר העסקה ששולם, ככל ששולם, תבטל את החיוב בשל העסקה, תמסור עותק מהודעת ביטול החיוב ולא תגבה מהרוכש סכום כלשהו, למעט דמי טיפול בסך 5% מערך העסקה או 100 ₪, לפי הנמוך מביניהם וזאת מבלי לגרוע מיתר זכויות החברה בהתאם להוראות החוק. על הרוכש להחזיר את המוצר וכל נכס אחר שקיבל בעקבות עשיית העסקה לתא הדואר של החברה 11283, מיקוד 91112 ירושלים. עלות ההחזרה חלה על הרוכש ותשולם על ידו לחברה ככל שהחזרת המוצר תתבצע באמצעות שליח מטעם החברה.
                        </p>

                        <h2>ביטול העסקה על ידי החברה</h2>
                        <p>
                            38.	מבלי לגרוע מהוראות תקנון זה, בכל אחד מהמקרים המפורטים להלן תהיה החברה רשאית לפסול משתמש ו/או לבטל את ההזמנה ו/או ההתקשרות עם המשתמש. הצעה שנפסלה ו/או התקשרות שבוטלה לא יזכו את המשתמש בכל סעד שהוא ולא יהיו למשתמש כל טענות כנגד החברה:
                            <ul>
                                <li>במקרה שבו תיפול טעות באחד מן הנתונים המפורטים באתר לגבי המוצר, לרבות מחיר המוצר או תיאורו על בסיסם נערכה ההזמנה.</li>
                                <li>מקרה של השבתה או תקלה בפעילותו התקינה של האתר עקב “כוח עליון” ו/או, עקב מקרה של תקלה מהותית במערכת המחשב, במערכת הטלפונים או במערכות תקשורת אחרות המשרתות את החברה, ו/או בכל מקרה אחר בו יעכבו, ימנעו או ישבשו גורמים ו/או אירועים שאינם בשליטת החברה את פעילותו התקינה של האתר או את אספקת המוצרים.</li>
                                <li>במקרה בו לא עמד המשתמש בהתחייבות מהתחייבויותיו לרבות, אך לא רק, ההתחייבות לתשלום התמורה בגין המוצר שהזמין ו/או בכל מקרה בו מסר המשתמש פרטים לא נכונים ו/או לא מדויקים ו/או לא מושלמים, לרבות במקרה של אי אישור העסקה על ידי חברת כרטיסי האשראי.</li>
                                <li>במקרה ונציג שירות הלקוחות של החברה לא יוכל לייצור קשר עם המזמין.</li>
                                <li>בכל מקרה אחר ובהתאם לשיקול דעתם הבלעדי של מנהלי החברה, רשאית החברה לבטל את העסקה ללא כל נימוק וללא הודעה מראש.</li>
                            </ul>
                        </p>
                        
                        <h2>מועדי אספקה, הובלה ומשלוח</h2>
                        <p>
                            39.	בעת רכישת המוצר מצוין באתר מספר ימי האספקה במידה ולא צוין ימי האספקה יהיו עד 21 ימי עסקים.
                        </p>
                        <p>
                            40.	ספירת ימי עסקים תהיה מיום א’–ה’ שישי שבת וימי חג לא יחשבו כימי עסקים.
                        </p>
                        <p>
                            41.	רכישה טלפונית ללא אמצעי תשלום מראש אינו מחייב את החברה לספק את המוצר ו/או לשריין את המוצר ו/או מחירו.
                        </p>
                        <p>
                            42.	אופן המשלוח ועלותו(במידה ובחר אופציה עם עלות) ייקבעו ע”י המזמין תוך בחירת האפשרויות העומדות לרשותו באתר.
                        </p>
                        <p>
                            43.	התחייבות האספקה הינה התחייבותנו להוציא את המשלוח מצידינו ללקוח עד ימי האספקה המצוינים, אך יש לקחת בחשבון חריגות הקשורות לחברת השליחויות מטעמים אובייקטיביים.
                        </p>
                        <p>
                            44.	מרגע משלוח המוצר זמן ההגעה תלוי אך ורק בסוג השילוח שבחר המזמין.
                        </p>
                        <p>
                            45.	אספקת המוצרים תתבצע בתחומי הקו הירוק בלבד ובהתאם למפת חלוקת המשלוחים המצוייה באתר וכפופה למפת חלוקת המשלוחים וימי החלוקה של חברת החלוקה בלבד.
                        </p>
                        <p>
                            46.	חובה על המזמין לבדוק שאיזורו נמצא במפת החלוקה והוא נמצא בימי החלוקה המצויינים לאיזור מגורו
                        </p>
                        <p>
                            47.	במקרה של איסוף עצמי(במידה וצויינה באתר אפשרות כזו) חלה חובה לוודא מראש שהמוצר ישנו ולתאם את איסופו עם צוות האתר.
                        </p>

                        <h2>אחריות</h2>
                        <p>
                            51.	המוצרים באתר מסופקים עם תעודת אחריות כדין. המוצרים המוצעים לרכישה במסגרת האתר הינם חדשים לחלוטין, באריזותיהם המקוריות ונהנים מכל האחריות והשירות המקובלים בהתאם לסעיף האחריות המצויינת במדריך המגיע עם המוצר, מסגרת האחריות הקובעת הינה נמצאת באתר האינטרנט babybeep.co.il בחלק שמציין את האחריות ומתעדכן מעת לעת.
                        </p>
                        <p>
                            52.	במקרה של צורך הרוכש בהפעלת האחריות עלות משלוח המוצר והחזרתו יחולו על הרוכש ויהיו באחריותו וכל זאת כמובן לאחר שהחברה אישרה את ההחלפה והעמידה בתקנות האחריות.
                        </p>
                        <p>
                            53.	כל סימני פגיעה או נזק ובלאי לא סביר במוצר, ו/או תיקון ע”י גורם לא מוסמך, ע”י יצרן, ו/או החברה, יגרמו לפקיעת האחריות, במקרה כזה עלות הנזק תחול על הרוכש.
                        </p>
                        <p>
                            54.	הזכות להעדיף תיקון המוצר על החלפתו ולהיפך שמורה לשיקול דעתה של החברה בלבד.
                        </p>                    
                        
                        <h2>פרסומות ותוכן</h2>
                        <p>
                            58.	תכונות, דגמים, עיצוב, צבעים או חזות של מוצרים המתוארים או מוצגים באתר, לרבות כל תמונה או הצגה של מוצר הכלולים באתר, הינם על מנת לדמות למשתמש את המוצר המבוקש בצורה הקרובה והטובה ביותר.
                        </p>
                        <p>
                            59.	תנאיו ותוקפו של כל מבצע המפורסם באתר יהיו כפופים למלאי שהוקצב לאותו מבצע כמפורסם באתר אם היה פרסום כזה.
                        </p>
                        <p>
                            60.	באתר עשויים להיכלל קישורים (‘לינקים’) לאתרי אינטרנט אחרים, שאינם מופעלים ע”י החברה. הקישורים נועדו אך ורק לנוחותם ולידיעתם של המשתמשים. קישורים אלו אינם בשליטתה של החברה והיא אינה מפקחת על תוכן האתרים המקושרים. ככל שהאתר מכיל קישורים אלו, אין בכך כדי להעיד על הסכמה ו/או אחריות של החברה לתכנים המופיעים באתרים המקושרים ו/או מהווה ערובה לאמינותם, עדכניותם, תקינותם או חוקיותם ו/או למדיניות הפרטיות ותנאי השימוש הננקטים על ידי בעליהם. החברה אינה אחראית לכל תוצאה שתיגרם כתוצאה מהשימוש באתרים המקושרים ו/או מהסתמכות עליהם וממליצה לקרוא בעיון את תנאי השימוש ומדיניות הפרטיות של אתרים אלו, ככל שקיימים, ו/או לפנות לבעלי האתרים המקושרים בכל מקרה של טענה או בקשה בעניין. החברה רשאית לפי שיקול דעתה הבלעדי להסיר כל קישור מהאתר ו/או להוסיף קישורים נוספים. החברה אינה מתחייבת כי הקישורים לאתרי האינטרנט האמורים יהיו פעילים ויובילו לאתר אינטרנט פעיל.
                        </p>
                        <p>
                            61.	כל שימוש בתוכן באתר, לרבות בתוכן המסופק על ידי החברה (לרבות מדריכי קניה, מאמרים, מפרטים, הוראות שימוש ו/או תמונות) ו/או בתוכן של צד שלישי, לרבות כל כניסה של המשתמש לאתרים של צדדים שלישיים על ידי הפניה באתר ו/או בפרסומת באתר, יתבצע על אחריותו הבלעדית של המשתמש. למשתמש לא תהיה כל טענה ו/או תביעה כנגד החברה בקשר לתוכן באתר, לרבות לתוכן המסופק על ידי החברה ו/או לתוכן של צד שלישי וכן, לרבות תוכן לגבי מוצרים ו/או מבצעים, בגין כל נזק ישיר או עקיף, הנובע משימוש ו/או כניסה כאמור ו/או בשל הסתמכות על תוכן של צד שלישי ו/או בשל פגיעה בפרטיות בעקבות שימוש כאמור.
                        </p>
                        <p>
                            62.	החברה משתדלת שהמידע המובא באתר יהיה מדויק ככל הניתן. עם זאת, ייתכנו באתר ו/או בתוכן ובמידע המובאים בו, לרבות לגבי כל מוצר ו/או שירות ו/או מידע על מבצעים, שיבושים, אי דיוקים וטעויות, בין היתר עקב מעשים ו/או מחדלים של החברה ו/או מי מטעמה ו/או של צדדים שלישיים. המשתמש פוטר בזאת את החברה מכל אחריות בשל המידע ו/או התוכן המובא על ידה באתר ו/או טעויות בו, לרבות בגין כל נזק, ישיר או עקיף, הנגרם למשתמש בשל כל תוכן ו/או המידע כאמור ו/או הסתמכות המשתמש עליו, לרבות בקשר למוצרים, תיאורים, מחירים וכדומה.
                        </p>

                        <h2>בעלות וקניין רוחני</h2>
                        <p>
                            63.	כל המידע, המדיה, התוכן, האיקונים, התמונות, הסמלים, הסרטונים, כתבות, הנפשות, ממשקים וכל מה שמופיע באתר בין אם מפורשות ובין אם לאו הינו בבעלות Team6tech בע״מ החברה המפעילה את האתר babybeep.co.il ולא יעשה שום שימוש, העתקה או כל דבר אחר הפוגע בחברה על פי דעתה הבלעדית
                        </p>
                        <p>
                            64.	מבלי לגרוע מהאמור, שם החברה וסימני המסחר שלה, לרבות אובייקטים הקשורים למוניטין החברה, כגון (אך לא רק): סימנים, לוגו, סמלילים, סימני שירות, או כל אובייקט אחר כפי שהם מופיעים באתר, הינם קניינה הבלעדי של החברה ואין לעשות בהם כל שימוש.
                        </p>

                        <h2>הגבלת אחריות</h2> 
                        <p>
                            69.	החברה עושה מאמצים רבים כדי לספק לך חווית שימוש איכותית ובטוחה באתר. עם זאת, האתר אינו חסין מתקלות ו/או בעיות ועשויה שלא להיות לך גישה לאתר מעת לעת.
                        </p>
                        <p>
                            70.	ידוע למשתמש, כי האתר, לרבות כל תוכן ו/או שירות בו, ניתנים על ידי החברה במצבם כפי שהם ובכפוף לזמינותם (‘As Is’ ו- ‘As Available’). החברה לא תישא בכל אחריות שהיא, מפורשת או משתמעת בקשר עם האתר, לרבות כל תוכן או שירות בו, וכן בקשר עם התאמתם למטרה ספציפית ו/או לדרישות המשתמש. המשתמש מצהיר ומתחייב בזה, כי הוא אחראי באופן בלעדי ומלא לכל שימוש שהוא עושה באתר, לרבות בכל תוכן ו/או שירות בו, וכי ידוע לו שהחברה אינה אחראית, בין במישרין בין בעקיפין, לכל שימוש שהוא עושה באתר כאמור.
                        </p>
                        <p>
                            71.	החברה ו/או מי מטעמה, לא יישאו בכל אחריות שהיא, לנזק ו/או אובדן, ישיר או עקיף, לרבות נזק נלווה, תוצאתי, אקראי או פיצויים עונשים (ובכלל זה, ומבלי לגרוע מכלליות האמור, פיצויים בגין הפסד עבודה ועסקים, הפסד רווחים, הפסקות והפרעות עבודה, הפסד ו/או איבוד מידע עסקי, פגיעה במוניטין, וכל הפסד ו/או נזק כספי אחר, צפוי ושאינו צפוי), הנובעים ו/או הקשורים, באתר, או בכל תוכן ו/או שירות בו, או בכל שימוש בהם, ולרבות אך מבלי לגרוע:
                        </p>
                        <p>
                            <ul>
                                <li>בשימוש ו/או אי יכולת שימוש באתר, לרבות כל תוכן ו/או שירות בו, מכל סיבה שהיא;</li>
                                <li>ודעות ו/או קבצים שנתקבלו אצל המשתמש במהלך ו/או עקב השימוש באתר, לרבות כל תוכן ו/או שירות בו;</li>
                                <li>שימוש או הסתמכות על מידע ותכנים המתפרסמים באתר, בין על ידי החברה ובין על ידי צדדים שלישיים;</li>
                                <li>כל מעשה ו/או מחדל המתבצע בתוכן האתר או בקשר עמו לאחר מסירתו לצדדים שלישיים;</li>
                                <li>הפרעות, זמינות ותקינות של האתר, לרבות כל תוכן ו/או שירות בו, מכל סיבה שהיא, ולרבות, הנובעים משיבושים או מכשלים ברשת האינטרנט או ברשת הטלפוניה;</li>
                                <li>נזק או אובדן אשר נגרם כתוצאה משגגה, טעות, אי דיוק וכיו”ב בתוכן באתר.</li>
                                <ll>שיבוש בקליטת פרטי לקוח במחשבי החברה מכל סיבה שהיא, לרבות תקלה הקשורה לחברה לא יהווה עילה לכל טענה ו/או תביעה מצד המשתמשים גם אם הצעה של משתמש לרכישת מוצר ו/או רכישת מוצר בפועל לא נקלטו במחשבי החברה עקב כך.</ll>
                                <li>אין בהגבלת האחריות בסעיף ‏זה, בכדי לגרוע מכל הגבלת אחריות אחרת המפורטת בתנאי השימוש, לרבות מדיניות הפרטיות.</li>
                            </ul>
                        </p>
                        
                        <h2>ציות לתנאים ושיפוי</h2>
                        <p>
                            72.	המשתמש מתחייב בזאת לציית לכל הוראות הדין, לרבות חוק ו/או תקנה, העשויים לחול על שימושו באתר ו/או בתוכנו, לרבות תנאי שימוש אלו.
                        </p>
                        <p>
                            73.	כמו כן, המשתמש מתחייב לשפות את החברה ו/או מי מטעמה בגין כל נזק, הפסד, אובדן רווח, תשלום או הוצאה שיגרמו להם, במישרין או בעקיפין, ובכלל זה פגיעה במוניטין, פגיעה כלכלית ו/או מסחרית, שכ”ט עו”ד והוצאות משפט בגין כל טענה, דרישה ו/או תביעה הנובעים, במישרין ו/או בעקיפין, מהפרת תנאי השימוש ו/או כל ממעשה ו/או מחדל של המשתמש ו/או מכל חיוב בו תחויב החברה ו/או מי מטעמה אשר על פי תנאי השימוש לחברה אין אחריות לגביו; או שתועלה נגדם על ידי צד שלישי כלשהו כתוצאה משימוש פסול ו/או בלתי חוקי על ידי המשתמש באתר ו/או בתוכנו, לרבות הפרת תנאים אלו.
                        </p>

                        <h2>התיישנות</h2>
                        <p>
                            74.	מבלי לגרוע מהאמור בתקנון תנאי שימוש זה, המשתמש מודע, מסכים ומאשר בזאת כי תקופת ההתיישנות לגבי כל תביעה ו/או טענה, ו/או דרישה כנגד החברה ו/או צדדים שלישיים הקשורים לשימוש באתר על ידי המשתמש ו/או למוצרים המופיעים באתר, תוגבל לתקופה של 6 חודשים וכלל הצדדים רואים בכך כהסכם לתקופת ההתיישנות.
                        </p>

                        <h2>סמכות שיפוט וברירת דין</h2>
                        <p>
                            75.	על כל שימוש באתר ו/או על כל תביעה, דרישה או בקשה הנובעים משימוש זה ו/או מכל דבר ועניין אחר הקשורים באופן ישיר או עקיף לאתר זה יחולו דיני מדינת ישראל בלבד. מקום השיפוט יהיה בבית המשפט לו הסמכות בירושלים, ישראל בלבד, והדין המהותי והפרוצדוראלי שיחול יהיה הדין הישראלי, אשר יגבר על כל כללי ברירת דין המפנים לתחולת דין זר.
                        </p>
                        
                        <h2>שונות</h2>
                        <p>
                            76.	תנאי שימוש אלו, המפורטים לעיל אינם יוצרים ולא יפורשו כיוצרים כל שותפות, מיזם משותף, יחסי עובד מעביד, סוכן או שלוח בין המשתמש לבין החברה.
                        </p>
                        <p>
                            77.	אם תנאי מתנאי השימוש ימצא בלתי חוקי, בטל או בלתי אכיף מכל סיבה, תנאי זה ימחק מתנאי השימוש ומחיקתו לא תשפיע על חוקיות ותקפות תנאי השימוש הנותרים. במקרה שכזה, יראו בקיומו של תנאי דומה אחר אכיף במקום התנאי שנמחק או הוסר.
                        </p>
                        <p>
                            78.	תנאי שימוש אלו מהווים את ההסכם המלא בינך לבין החברה בכל הנוגע לאופן השימוש באתר ומחליפים כל הבנה ו/או הסכמה אחרת, בעל-פה או בכתב, הנוגעת לשימוש באתר ובתוכנו.
                        </p>                    
                        
                        <h2>יצירת קשר</h2>
                        <p>
                            79.	בכל עניין, שאלה ו/או בקשה, אנא פנו אל שירות הלקוחות של החברה באמצעי הקשר המפורטים באתר babybeep.co.il, לחלופין, הינך מוזמן לשלוח מכתב לכתובת: תא דואר 11283 מיקוד 91112 ירושלים
                        </p>

                        <p className="font-weight-bold">
                            תודה שבחרתם באתר babybeep.co.il אנו מאחלים לכם חווית גלישה מהנה.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
  }
}