import React, { Component } from "react";

export default class PageNotFound extends Component {
  render() {
    return (
        <div>
            <div className="row mb-2">                
                <div className="col-md-12 text-center">
                    <h2>Page Not Found (404)</h2>
                </div>
            </div>
        </div>
    );
  }
}