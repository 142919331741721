import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import CardSection from '../components/cardsection';
import { AccordionComponent, AccordionItemDirective, AccordionItemsDirective } from '@syncfusion/ej2-react-navigations';
import faqList from '../data/faq_he.json';
import {Animated} from "react-animated-css";
import 'react-lazyload-youtube/dist/index.css'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import CrossfadeImage from "react-crossfade-image";

const images = [
    'https://static.babybeep.co.il/images/babybeep-blue.jpg',
    'https://static.babybeep.co.il/images/babybeep-gray.jpg',
    'https://static.babybeep.co.il/images/babybeep-pink.jpg',
];

export default class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            imageIndex: 0            
        };
    }

    componentDidMount() {
        // this.intervalImage = setInterval(() => {
        //     this.state.imageIndex === 2 ? clearInterval(this.intervalImage) : this.setState({ imageIndex: this.state.imageIndex + 1 });
        // }, 2500);
        
        setInterval(() => {
            this.state.imageIndex === 2 ? this.setState({ imageIndex: 0 }) : this.setState({ imageIndex: this.state.imageIndex + 1 });
        }, 2500);
    }

    render() {
        return (
            <>
                <Helmet>                   
                    <title>Baby beep - צליל מציל חיים</title>
                    <meta property="og:title" content="Baby beep - צליל מציל חיים" />
                    <meta name="description" content="Baby beep הינה מערכת המורכבת מכרית חכמה המונחת על כל מושב בטיחות ומאפליקציית Baby beep יעודית להורים ולכל המסיע ילדים עד גיל 5 ברכב פרטי. מערכת Baby beep מסייעת לכם ההורים לשמור על בטיחותם של הילדים בזמן הנסיעה ברכב כך שלא תשכחו אותם ברכבכם בגמר הנסיעה." />
                    <meta property="og:description" content="Baby beep הינה מערכת המורכבת מכרית חכמה המונחת על כל מושב בטיחות ומאפליקציית Baby beep יעודית להורים ולכל המסיע ילדים עד גיל 5 ברכב פרטי. מערכת Baby beep מסייעת לכם ההורים לשמור על בטיחותם של הילדים בזמן הנסיעה ברכב כך שלא תשכחו אותם ברכבכם בגמר הנסיעה." />
                </Helmet>
                <CardSection className="header-banner pb-xl-0 d-none d-xl-block">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-xl-5 text-xl-right pr-lg-5">
                                <Animated animationIn="fadeIn" animationInDuration={1200}>
                                    <h1 className="mb-4">מהיום לא שוכחים את האהובים עליכם ברכב!</h1>
                                    <h2 className="mb-4">התקדם עוד היום למערכת Baby beep מערכת למניעת שכחת ילדים ברכב</h2>
                                </Animated>
                                <div className="text-center isit-img">
                                    <img src="https://static.babybeep.co.il/images/isit.png" alt="מוצר איטלקי, אפליקציה ישראלית"></img>
                                </div>
                                <div className="text-center isit-text">
                                    <h3>מערכת Baby beep <a href="https://static.babybeep.co.il/pdf/21_013-034646-01.pdf" target="_blank" rel="noopener noreferrer" aria-label="לחץ כאן לצפייה באישור על כך שהמוצר עומד בתקנות משרד התחבורה">מקיימת את הדרישות המפורטות בתקנה 83ד לתקנות התעבורה בישראל</a> ועומדת בתקן ובחוק האיטלקי המחייב התקנת מערכת למניעת שכחת ילדים ברכב עבור הסעת ילדים ברכב פרטי מתחת לגיל 4, הוראה זו תכנס לתוקף בישראל החל מה-1 באוגוסט 2021</h3>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-5">
                                    <img src="https://static.babybeep.co.il/images/header-product.webp" className="header-product-img img-fluid" alt="כרית Baby beep בצבע תכלת"></img>
                                </div>
                            </div>
                            <img src="https://static.babybeep.co.il/images/header-screen.png" className="header-screen" alt="מסך מתוך האפליקציה"></img>
                            <img src="https://static.babybeep.co.il/images/299bf.png" className="header-price-img" alt="מבצע בלאק פריידי 299 שקלים חדשים"></img>
                        </div>
                    </div>
                </CardSection>
                <CardSection className="header-banner-mobile pb-0 d-block d-xl-none">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 p-0 header-price-img-mobile">
                                <Animated animationIn="fadeIn" animationInDuration={1200}>
                                    <img src="https://static.babybeep.co.il/images/299bf.png" alt="מבצע בלאק פריידי 299 שקלים חדשים" className="img-price"></img>
                                    <img src="https://static.babybeep.co.il/images/mom-son-gr.jpg" className="img-fluid w-100" alt="תמונה באנר ראשי אם וילד"></img>
                                </Animated>
                            </div>
                            <div className="col-12 text-center isit-img">
                                <img src="https://static.babybeep.co.il/images/isit.png" alt="מוצר איטלקי, אפליקציה ישראלית"></img>
                            </div>
                            <div className="col-12 text-center">
                                <Animated animationIn="fadeIn" animationInDuration={1200}>
                                    <h1 className="mb-4">מהיום לא שוכחים את האהובים עליכם ברכב!</h1>
                                    <h2 className="mb-4">התקדם עוד היום למערכת Baby beep מערכת למניעת שכחת ילדים ברכב</h2>
                                </Animated>
                                <div className="isit-text">
                                    <h3>מערכת Baby beep <a href="https://static.babybeep.co.il/pdf/21_013-034646-01.pdf" target="_blank" rel="noopener noreferrer" aria-label="לחץ כאן לצפייה באישור על כך שהמוצר עומד בתקנות משרד התחבורה">מקיימת את הדרישות המפורטות בתקנה 83ד לתקנות התעבורה בישראל</a> ועומדת בתקן ובחוק האיטלקי המחייב התקנת מערכת למניעת שכחת ילדים ברכב עבור הסעת ילדים ברכב פרטי מתחת לגיל 4, הוראה זו תכנס לתוקף בישראל החל מה-1 באוגוסט 2021</h3>
                                </div>
                            </div>
                            <div className="col-12">                                
                                <CrossfadeImage
                                    src={images[this.state.imageIndex]}
                                    duration={2000}
                                    timingFunction={"ease"}
                                />
                            </div>
                        </div>
                    </div>
                </CardSection>
                {/* <div className="header-strip d-none d-xl-block"></div> */}
                <div className="container-fluid youtube">
                    <div className="row">
                        {/* <Youtube width="100%" height="900px" videoId="qyT0KGcDrO8" imgSize="maxresdefault"></Youtube> */}
                        <div className="embed-responsive embed-responsive-16by9">
                            <iframe
                                className="embed-responsive-item"
                                frameBorder="0"
                                allowFullScreen="1"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                title="YouTube video player"
                                width="100%"
                                height="100%"
                                src="https://www.youtube.com/embed/qyT0KGcDrO8?disablekb=1&amp;autoplay=0&amp;controls=0&amp;showinfo=0&amp;rel=0&amp;loop=1&amp;wmode=transparent&amp;origin=https%3A%2F%2Fwww.babybeep.co.il&amp;widgetid=1">
                            </iframe>
                        </div>
                    </div>
                </div>
                <CardSection className="aboutus" headline="Baby beep">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 align-self-center">
                                <div className="two-col text-justify">
                                    <h3>
                                        מה זה?
                                    </h3>
                                    <p>
                                        Baby beep הינה מערכת המורכבת מכרית חכמה המונחת על כל מושב בטיחות ומאפליקציית Baby beep יעודית להורים ולכל המסיע ילדים עד גיל 5 ברכב פרטי. מערכת Baby beep מסייעת לכם ההורים לשמור על בטיחותם של הילדים בזמן הנסיעה ברכב כך שלא תשכחו אותם ברכבכם בגמר הנסיעה.
                                    </p>
                                    <h3>
                                        קצת על המוצר
                                    </h3>
                                    <p>
                                        מערכת Baby beep מתריע על ידי צפצוף מתאים וחיווי ויזואלי על גבי מכשיר הטלפון באפליקציה של ההורה, ברגע שההורה מתרחק מהרכב עם הסמארטפון שלו והילד נשכח ברכב.
                                        המערכת תשלח מסרונים בליווי קישור למיקום הרכב על גבי מפה לאנשי הקשר בחירום שהוגדרו בהתקנת האפליקציה במקרה בו נשכח ילד ברכב.
                                    </p>
                                    <p>
                                        במקביל המערכת תצפצף, תשלח מסרונים והתרעות במקרים בהם נותק רכיב הבלוטות׳ או שהרכב נמצא במצב סטטי מעל 10 דקות, כשסוללת המכשיר נמוכה, כאשר שכחתי את הסמארטפון ברכב או כשהגעתי לרכב ללא סמארטפון. מערכת Baby beep מכסה את האפשרויות כך שלא תשכח את ילדך ברכב.
                                    </p>
                                    <p>
                                        הכרית מצוידת במנגנון בדיקת תקינות אוטומטית כך שהכרית תצפצף צפצוף יחיד בכל פעם שהילד יתפוס את מקומו במושב ושני צפצופים בקימת הילד מהמושב, כך שאתה ההורה תדע שהמערכת תקינה, יחד עם זאת בכל ישיבה וקימה של הילד ההורה יקבל חיווי במכשיר הטלפון המשויך.
                                    </p>
                                    <p>
                                        מערכת Baby beep משווקת כבר היום ברשתות המובילות באיטליה ועברה את כל ההתקנים המחייבים בארץ המגף. באיטליה קיים כבר היום חוק פעיל המחייב התקנת מערכת למניעת שכחת ילדים ברכב פרטי לכל המסיע ילדים עד גיל 4.
                                    </p>
                                    <h3>
                                        מי אנחנו
                                    </h3>
                                    <p>
                                        חברת Team6tech בע״מ הישראלית אשר פיתחה ומשווקת את המוצר, מהיום לא רק באיטליה אלא גם בישראל! אפליקציית baby beep ישראל שוכתבה והותאמה לצרכן הישראלי יחד עם אתר אינטרנט זה. באתר תמצאו את כל המידע והעזרה הדרושה לתפעול המערכת: מדריכים, סרטון הסבר, שאלות ותשובות, מענה אישי בוואטסאפ, טלפון למוקד טלפוני מאויש, עמודי פייסבוק ואינסטגרם וכמובן אפשרות ליצירת קשר באמצעות האתר עצמו.
                                    </p>
                                    <p>
                                        המערכת מורכבת מכרית אשר מיוצרת 100% באיטליה ועומדת בתקנים המחמירים ביותר ומותאמת לחוק האיטלקי ומאפליקציה ישראלית – כחול לבן. יחד הם חוברים לכדי מוצר מנצח ואיכותי שנמכר היום באיטליה וכעת גם בישראל, התקדמו עוד היום למערכת Baby beep למען ביטחון ילדכם ותיהנו ממשלוח חינם - עד הבית!
                                    </p>
                                    <p className="font-weight-bold">
                                        אל תתנו לשמש לשמור לכם על הילדים
                                    </p>
                                    <LazyLoadImage src="https://static.babybeep.co.il/images/handbt.png" alt="תמונת יד אוחזת מסך אפליקציה"></LazyLoadImage>
                                </div>
                            </div>
                            <div className="col-6 d-none d-lg-block app-screen-about">
                                <LazyLoadImage src="https://static.babybeep.co.il/images/appscreenabout.png" alt="מסך מתוך אפליקציית Baby beep"></LazyLoadImage>
                            </div>
                        </div>
                    </div>
                </CardSection>
                <CardSection className="howwork" headline="איך זה עובד?">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="row mb-5">
                                    <div className="col-md-5 mb-4 mb-md-0">
                                        <h3>כל אחד עלול לשכוח את הילד ברכב כל. אחד.</h3>
                                    </div>
                                    <div className="col-md-7 text-justify">
                                        <p>
                                            כאשר מערכת BabyBeep מזהה שמכשיר הטלפון הנייד שלכם מתרחק מהרכב, תופיע על צג מכשיר הסלולר של הנהג התראה מתאימה בליווי צפצוף עד לחזרת המכשיר הנייד חזרה לרכב. במידה והנהג לא חזר לרכב תוך שתי דקות או לא ביטל את ההתראה במכשירו, יישלחו מסרונים לאנשי הקשר שהוגדרו מראש באפליקציה, בצירוף קישור למיקום האחרון בו ממוקם הרכב.
                                        </p>
                                        <p>
                                            מזהה את נוכחות הילד ולא מבוסס משקל בלבד |
                                            התראת סוללה נמוכה |
                                            בד נושם |
                                            היפואלרגני |
                                            תומך ב- IOS 11 ואנדרואיד 5 ומעלה |
                                            האפליקציה תומכת במספר שפות |
                                            מאושר ע"י מעבדות Radio Motive Italy המבטיח את בטחון ילדך |
                                            אינו משפיע על התקני Bluetooth אחרים ברכב |
                                            משתמש ב Bluetooth low Energy בהתאם לתקנות האירופאיות בנושא תקני רדיו לפי 2014/53 של האיחוד האירופי (RED) |
                                            עומד בתקן המחמיר Oeko-Tex Standard 100 לאישור וביקורת של חומרי הגלם בענף הטקסטיל לבריאות הצרכן
                                        </p>
                                    </div>
                                </div>
                                <Animated animationIn="bounce">
                                <div className="row mb-5">
                                    <div className="col-4">
                                        <LazyLoadImage src="https://static.babybeep.co.il/images/how1.png" className="img-fluid" alt="שלב 1"></LazyLoadImage>
                                    </div>
                                    <div className="col-4">                                    
                                        <LazyLoadImage src="https://static.babybeep.co.il/images/how2.png" className="img-fluid" alt="שלב 2"></LazyLoadImage>
                                    </div>
                                    <div className="col-4">
                                        <LazyLoadImage src="https://static.babybeep.co.il/images/how3.png" className="img-fluid" alt="שלב 3"></LazyLoadImage>
                                    </div>
                                </div>
                                </Animated>
                            </div>
                            <div className="col-4 d-none d-lg-block">
                                <LazyLoadImage src="https://static.babybeep.co.il/images/handapp.png" alt="מסך פתיחה מהאפליקציית Baby beep"></LazyLoadImage>
                            </div>
                        </div>                    
                    </div>
                </CardSection>
                <div className="how-stirp d-none d-xl-block"></div>
                <CardSection className="downloadapps">
                    <div className="container h-100">
                        <div className="row h-100 align-items-center">
                            <div className="col-md-8">
                                <h2>את אפליקציית Baby beep ניתן להוריד בחנויות האפליקציות</h2>
                            </div>
                            <div className="col-6 d-md-none text-center">
                                <a href="https://apps.apple.com/il/app/baby-beep/id1552867846?l=iw" target="_blank" rel="noopener noreferrer" aria-label="לחץ כאן להורדת אפליקציית Baby beep בחנות AppStore">
                                    <LazyLoadImage className="img-fluid" src="https://static.babybeep.co.il/images/appstore.png" alt="חנות AppStore"></LazyLoadImage>
                                </a>
                            </div>
                            <div className="col-6 d-md-none text-center">
                                <a href="https://play.google.com/store/apps/details?id=coil.baby.beep" target="_blank" rel="noopener noreferrer" aria-label="לחץ כאן להורדת אפליקציית Baby beep בחנות Google Play">
                                    <LazyLoadImage className="img-fluid" src="https://static.babybeep.co.il/images/googleplay.png" alt="חנות Google Play"></LazyLoadImage>
                                </a>
                            </div>
                            <div className="col-4 d-none d-md-block text-left">
                                <div className="mb-3">
                                    <a href="https://apps.apple.com/il/app/baby-beep/id1552867846?l=iw" target="_blank" rel="noopener noreferrer" aria-label="לחץ כאן להורדת אפליקציית Baby beep בחנות AppStore">
                                        <LazyLoadImage className="img-fluid" src="https://static.babybeep.co.il/images/appstore.png" alt="חנות AppStore"></LazyLoadImage>
                                    </a>
                                </div>
                                <div className="mb-3">
                                    <a href="https://play.google.com/store/apps/details?id=coil.baby.beep" target="_blank" rel="noopener noreferrer" aria-label="לחץ כאן להורדת אפליקציית Baby beep בחנות Google Play">
                                        <LazyLoadImage className="img-fluid" src="https://static.babybeep.co.il/images/googleplay.png" alt="חנות Google Play"></LazyLoadImage>
                                    </a>
                                </div>
                            </div>
                        </div>                  
                    </div>
                </CardSection>
                <CardSection className="faq" headline="שאלות ותשובות">
                    <div className="container">
                    {faqList.map((faq, index) => (
                        <AccordionComponent expandMode="Single" enableRtl={true} key={index}>
                            <AccordionItemsDirective>
                                <AccordionItemDirective
                                    header={faq.question}
                                    content={faq.answer}
                                    className="mb-5"
                                />
                            </AccordionItemsDirective>
                        </AccordionComponent>
                        ))}
                    </div>
                </CardSection>
                <div className="qa-strip d-none d-xl-block"></div>
                <CardSection className="logos">
                    <div className="container h-100">
                        <div className="row h-100 align-items-center">
                            <div className="col-4 col-md mb-4 mb-md-0 text-center">
                                <LazyLoadImage src="https://static.babybeep.co.il/images/oekotex.png" className="img-fluid" alt="OEKO-TEX"></LazyLoadImage>
                            </div>
                            <div className="col-4 col-md mb-4 mb-md-0 text-center">
                                <LazyLoadImage src="https://static.babybeep.co.il/images/ce.png" className="img-fluid" alt="CE"></LazyLoadImage>
                            </div>
                            <div className="col-4 col-md mb-4 mb-md-0 text-center">
                                <LazyLoadImage src="https://static.babybeep.co.il/images/radiomotiv.png" className="img-fluid" alt="Radio Motive"></LazyLoadImage>                            
                            </div>
                            <div className="col-4 col-md mb-4 mb-md-0 text-center">
                                <LazyLoadImage src="https://static.babybeep.co.il/images/erp.png" className="img-fluid" alt="European Recycling Platform"></LazyLoadImage>
                            </div>
                            <div className="col-4 col-md mb-4 mb-md-0 text-center">
                                <LazyLoadImage src="https://static.babybeep.co.il/images/antibacterial.png" className="img-fluid" alt="Anti Bacterial"></LazyLoadImage>
                            </div>
                            <div className="col-4 col-md mb-4 mb-md-0 text-center">
                                <LazyLoadImage src="https://static.babybeep.co.il/images/antistatic.png" className="img-fluid" alt="ANTI-STATIC"></LazyLoadImage>
                            </div>
                            <div className="col-4 col-md mb-4 mb-md-0 text-center">
                                <LazyLoadImage src="https://static.babybeep.co.il/images/isit.png" className="img-fluid" alt="מוצר איטלקי, אפליקציה ישראלית"></LazyLoadImage>
                            </div>
                        </div>                  
                    </div>
                </CardSection>
            </>
        );
    }
}