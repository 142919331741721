import React, { Component } from "react";
import { Helmet } from "react-helmet";
import OrderStep from '../components/orderstep'
import { TextBoxComponent, FormValidator } from '@syncfusion/ej2-react-inputs';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { ButtonComponent, CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import OrderService from '../services/order.service';
import CouponService from '../services/coupon.service';
import ShoppingCart from "../components/shoppingCart";
import ClipLoader from "react-spinners/ClipLoader";
import PulseLoader from "react-spinners/PulseLoader";
import ModalMessage from '../components/modalMessage'
import { FaCcVisa, FaCcMastercard, FaCcDinersClub, FaCcAmex } from 'react-icons/fa';
import ReactPixel from 'react-facebook-pixel';

const payments = [
    { code: 1, name: '1' },
    { code: 2, name: '2' },
    { code: 3, name: '3' },
]

export default class Checkout extends Component {
    constructor(props) {
        super(props);

        if (!localStorage.getItem('order')) {
            this.props.history.push('/order');
        }

        const order = JSON.parse(localStorage.getItem('order'));

        let orderTotal = 0;

        order.shoppingCart.map((item) => (
            orderTotal += (item.price * item.quantity)
        ))

        //orderTotal = order.shoppingCart[0]?.price * order.shoppingCart[0]?.quantity

        const monthes = [
            { code: '01', name: '01' },
            { code: '02', name: '02' },
            { code: '03', name: '03' },
            { code: '04', name: '04' },
            { code: '05', name: '05' },
            { code: '06', name: '06' },
            { code: '07', name: '07' },
            { code: '08', name: '08' },
            { code: '09', name: '09' },
            { code: '10', name: '10' },
            { code: '11', name: '11' },
            { code: '12', name: '12' },
        ];

        const years = [];
        const year = parseInt(new Date().getFullYear().toString().slice(-2));
        Array.from( new Array(10), (v,i) =>
            years.push({code: ((year+i).toString()), name: (year+i).toString()})
        );

        this.state = {
            cardName: '',
            cardIdNumber: '',
            cardNumber: '',
            cardExpirationMonth: '',
            cardExpirationYear: '',
            cardCvv2: '',
            paymentsNumber: '',
            couponName: '',
            couponDiscount: 0,
            useCoupon: false,
            orderTotal,
            orderTotalBeforeCoupon: orderTotal,
            confirm: false, 
            marketing: true,
            modalShow: false,
            order,
            monthes,
            years,
            btnLoader: false,
            btnCheckoutLoader: false
        };
    }

    componentDidMount() {
        if (!localStorage.getItem('order')) {
            this.props.history.push('/order');
        }

        window.scrollTo(0, 0)

        const options = {
            rules: {
                'cardName': {
                    required: [true, 'שדה חובה'],
                    maxLength: [50, 'מקסימום {0} תווים']
                },
                'cardIdNumber': {
                    required: [true, 'שדה חובה'],
                    maxLength: [9, 'מקסימום {0} תווים'],
                    number: [true, 'מספרים בלבד'],
                    regex: [this.validateId, 'מספר תעודת זהות לא חוקי']
                },
                'cardNumber': {
                    required: [true, 'שדה חובה'],
                    number: [true, 'מספרים בלבד'],
                    regex: [this.validateCreditCardnumber, 'מספר כרטיס אשראי לא חוקי']
                },
                'cardExpirationMonth': {
                    required: [true, 'שדה חובה']
                },
                'cardExpirationYear': {
                    required: [true, 'שדה חובה']
                },
                'cardCvv2': {
                    required: [true, 'שדה חובה'],
                    number: [true, 'מספרים בלבד'],
                    minLength: [3, 'מינימום {0} תווים'],
                    maxLength: [4, 'מקסימום {0} תווים']
                },
                'paymentsNumber': {
                    required: [true, 'שדה חובה']
                },
                'confirm': {                    
                    required: [true, 'חובה לאשר קריאת תנאי השימוש'],
                }
            }
        }

        // initialize the form validator
        this.formValidator = new FormValidator('#form1', options);
    }
    
    handleChange = (e) => {
        const value = e.value;
        const name = e.target.name;                

        this.setState({
            ...this.state,
            [name]: value
        });
    }

    handleCheck = (args) => {
        const value = args.checked;
        const name = args.event.target.name;

        this.setState({
            ...this.state,
            [name]: value
        });
    }

    handleDDLChange = (args) => {
        const value = args.value;
        const name = args.element.id;

        this.setState({
            ...this.state,
            [name]: value
        });
    }

    couponClick = (useCoupon) => {
        const { couponName, orderTotalBeforeCoupon } = this.state;

        if(useCoupon === true) {
            if (localStorage.getItem('order')) {

                this.setState({
                    btnLoader: true
                });

                const order = JSON.parse(localStorage.getItem('order'));
                const email = order.shipping.email;

                CouponService.getByName(couponName, email)
                    .then(response => {
        
                        if (response.data.status === 'success')
                        {
                            const couponDiscount = response.data.data.discount;
                            this.setState({
                                orderTotal: orderTotalBeforeCoupon - (orderTotalBeforeCoupon * couponDiscount / 100),
                                useCoupon,
                                couponDiscount,
                                btnLoader: false
                            });
                        }
                        else {
                            this.setState({
                                modalHeader: 'אופס...',
                                modalBody: 'קוד הקופון שהוקלד אינו תקין, אינו תקף או אינו קיים במערכת, אנא בדוק את קוד הקופון ונסה שנית.',
                                modalShow: true,
                                btnLoader: false
                            });
                        }
                    })
                    .catch(e => {
                        this.setState({
                            modalHeader: 'אופס...',
                            modalBody: 'קוד הקופון שהוקלד אינו תקין, אינו תקף או אינו קיים במערכת, אנא בדוק את קוד הקופון ונסה שנית.',
                            modalShow: true,
                            btnLoader: false
                        });
                    });
            }
        }
        else {
            this.setState({
                orderTotal: orderTotalBeforeCoupon,
                useCoupon,
                couponName: ''
            });
        }
    }

    submitClick = () => {
        if (this.formValidator.validate()) {
            if (localStorage.getItem('order')) {

                this.setState({
                    btnCheckoutLoader: true
                });

                let order = JSON.parse(localStorage.getItem('order'));

                const { cardName, cardIdNumber, cardNumber, cardExpirationMonth, cardExpirationYear, cardCvv2, paymentsNumber, confirm, marketing, orderTotal, useCoupon, couponName, couponDiscount } = this.state;            

                order = { ...order,
                    payment: {
                        cardName,
                        cardIdNumber,
                        cardNumber,
                        cardExpirationMonth,
                        cardExpirationYear,
                        cardCvv2,
                        paymentsNumber,
                        confirm,
                        marketing,
                        orderTotal
                    },
                    coupon: {
                        useCoupon,
                        couponName,
                        couponDiscount
                    }
                };

                OrderService.create(order)
                    .then(response => {
                        if (response.data.status === 'success')
                        {
                            localStorage.removeItem('order');

                            ReactPixel.track("Purchase", {currency: "ILS", value: orderTotal});

                            this.props.history.push("/end", { orderId: response.data.data.orderId })
                        }
                        else {
                            this.setState({
                                modalHeader: 'אופס...',
                                modalBody: 'ארעה שגיאה בתהליך הרכישה, בדוק שכל הנתונים שהזנת אכן תקינים ונכונים.',
                                modalShow: true,
                                btnCheckoutLoader: false
                            });
                        }
                    })
                    .catch(e => {
                        this.setState({
                            modalHeader: 'אופס...',
                            modalBody: 'ארעה שגיאה בתהליך הרכישה, בדוק שכל הנתונים שהזנת אכן תקינים ונכונים.',
                            modalShow: true,
                            btnCheckoutLoader: false
                        });
                    });
            }
            else {
                this.props.history.push('/order');
            }
        }
        else {
            //document.getElementsByTagName("form")[0]?.querySelector("input.e-error")?.focus();
        }
    }

    modalClose = () => {
        this.setState({
            modalShow: false
        })
    }

    validateCreditCardnumber(cardNo) {      
        if (cardNo === null || cardNo === '' || cardNo === undefined)
        {
            return false;
        }

        var s = 0;
        var doubleDigit = false;
        for (var i = cardNo.length - 1; i >= 0; i--) {
            var digit = +cardNo[i];
            if (doubleDigit) {
                digit *= 2;
                if (digit > 9)
                    digit -= 9;
            }
            s += digit;
            doubleDigit = !doubleDigit;
        }
        return s % 10 === 0;
    };

    validateId = (args) => {
        let strId = String(args.value).trim();
        if (strId.length > 9) {
           return false;
        }
        if (strId.length < 9) {
           while (strId.length < 9) strId = "0" + strId;
        }
        let counter = 0, rawVal, actualVal;
        for (let i = 0; i < strId.length; i++) {
           rawVal = Number(strId[i]) * ((i % 2) + 1);
           actualVal = rawVal > 9 ? (rawVal - 9) : rawVal;
           counter += actualVal;
        }
        return (counter % 10 === 0);
     };

    render() {
        const { cardName, cardIdNumber, cardNumber, cardExpirationMonth, cardExpirationYear, cardCvv2, paymentsNumber, couponName, orderTotal, confirm, marketing, order,
            useCoupon, couponDiscount, orderTotalBeforeCoupon, monthes, years, btnLoader, btnCheckoutLoader, modalShow, modalBody, modalHeader } = this.state;
        const coupon = { useCoupon, couponName, couponDiscount }

        return (
            <>
                <Helmet> 
                    <title>Baby beep - הזמן עכשיו</title>
                    <meta property="og:title" content="Baby beep - הזמן עכשיו" />
                    <meta name="description" content="ביפ קטן בשבילכם, ביטחון גדול לילדיםעם השלמת הרכישה, תוכלו גם אתם ליהנות מהביטחון המלא שהילדים שלכם שמורים ובטוחים ולא ישכחו ברכב. בחרו כמות וצבע, והכריות בדרך אליכם עם משלוח חינם עד הבית." />
                    <meta property="og:description" content="ביפ קטן בשבילכם, ביטחון גדול לילדיםעם השלמת הרכישה, תוכלו גם אתם ליהנות מהביטחון המלא שהילדים שלכם שמורים ובטוחים ולא ישכחו ברכב. בחרו כמות וצבע, והכריות בדרך אליכם עם משלוח חינם עד הבית." />
                </Helmet>
                
                <form id='form1' autoComplete="off">
                    <div className="container checkout py-5">
                        <div className="row justify-content-center">
                            <div className="col-12 mb-5">
                                <OrderStep
                                    step={3}>
                                </OrderStep>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-7">
                                <ShoppingCart shoppingCart={order.shoppingCart} orderTotal={orderTotal} coupon={coupon} orderTotalBeforeCoupon={orderTotalBeforeCoupon} showBack={true} showEdit={false} {...this.props}></ShoppingCart>
                            </div>
                            <div className="col-lg-5">
                                <div className="row">
                                    <div className="col mb-2">
                                        <h2>מימוש קופון</h2>
                                    </div>
                                </div>
                                <div className="row form-group">
                                    <div className="col">
                                        אם יש ברשותך קוד קופון הכנס אותו בתיבה מטה ולחץ על כפתור <strong>בדוק קופון</strong> כדי לוודא תקינותו. יש לראות כי הנחת הקופון מופיעה בטרם ביצוע התשלום
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="row form-group">
                                            <div className="col-12">
                                                <TextBoxComponent
                                                    id="couponName"
                                                    name="couponName"
                                                    placeholder="קוד קופון"
                                                    value={couponName}
                                                    onChange={this.handleChange}
                                                    disabled={useCoupon}
                                                    cssClass="e-outline"
                                                    floatLabelType="Auto"
                                                />
                                                {(useCoupon === true ? 
                                                    <ButtonComponent
                                                        onClick={() => this.couponClick(false)}                
                                                        cssClass="e-danger"
                                                        type="button"
                                                    >
                                                    בטל קופון <HighlightOffIcon />
                                                    </ButtonComponent>
                                                    : 
                                                    <ButtonComponent
                                                        onClick={() => this.couponClick(true)}
                                                        cssClass="e-primary"                                                        
                                                        style={{width: '116px'}}
                                                        type='button'
                                                    >
                                                        {btnLoader ? <ClipLoader color={'#fff'} loading={btnLoader} size={20} /> : 'בדוק קופון '}
                                                        {btnLoader ? '' : <CheckCircleOutlineIcon />}
                                                    </ButtonComponent>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col mb-2">
                                        <h2>פרטי בעל הכרטיס</h2>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="row form-group">
                                            <div className="col">
                                                <TextBoxComponent
                                                    id="cardName"
                                                    name="cardName"
                                                    placeholder="*שם בעל הכרטיס"
                                                    value={cardName}
                                                    onChange={this.handleChange}
                                                    cssClass="e-outline"
                                                    floatLabelType="Auto"
                                                />
                                                <label className='e-error' htmlFor='cardName' />
                                            </div>
                                            <div className="col">
                                                <TextBoxComponent
                                                    id="cardIdNumber"
                                                    name="cardIdNumber"
                                                    placeholder="*תעודת זהות"
                                                    type="number"
                                                    value={cardIdNumber}
                                                    onChange={this.handleChange}
                                                    cssClass="e-outline"
                                                    floatLabelType="Auto"
                                                />
                                                <label className='e-error' htmlFor='cardIdNumber' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col mb-2">
                                        <h2>פרטי כרטיס האשראי</h2>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="row">
                                            <div className="col cc-icons">
                                                <FaCcVisa className="ml-2"></FaCcVisa>
                                                <FaCcMastercard className="ml-2"></FaCcMastercard>
                                                <FaCcDinersClub className="ml-2"></FaCcDinersClub>
                                                <FaCcAmex></FaCcAmex>
                                            </div>
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-12">
                                                <TextBoxComponent
                                                    id="cardNumber"
                                                    name="cardNumber"
                                                    placeholder="*מספר כרטיס אשראי"
                                                    type="number"
                                                    value={cardNumber}
                                                    onChange={this.handleChange}
                                                    cssClass="e-outline"
                                                    floatLabelType="Auto"
                                                />
                                                <label className='e-error' htmlFor='cardNumber' />
                                            </div>
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-6">
                                                <DropDownListComponent 
                                                    id='cardExpirationMonth'
                                                    name="cardExpirationMonth"
                                                    placeholder="*בחר חודש"
                                                    value={cardExpirationMonth}
                                                    change={this.handleDDLChange}
                                                    dataSource={monthes}
                                                    fields={{ text: 'name', value: 'code' }}                                                    
                                                    noRecordsTemplate={"אין נתונים לתצוגה"}
                                                    cssClass="e-outline"
                                                    floatLabelType="Auto"
                                                    enableRtl={true}
                                                />
                                                <label className='e-error' htmlFor='cardExpirationMonth' />
                                            </div>
                                            <div className="col-6">
                                                <DropDownListComponent 
                                                    id='cardExpirationYear'
                                                    name="cardExpirationYear"
                                                    placeholder="*בחר שנה"
                                                    value={cardExpirationYear}
                                                    change={this.handleDDLChange}
                                                    dataSource={years}
                                                    fields={{ text: 'name', value: 'code' }}                                                    
                                                    noRecordsTemplate={"אין נתונים לתצוגה"}
                                                    cssClass="e-outline"
                                                    floatLabelType="Auto"
                                                    enableRtl={true}
                                                />
                                                <label className='e-error' htmlFor='cardExpirationYear' />
                                            </div>
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-6">
                                                <TextBoxComponent
                                                    id="cardCvv2"
                                                    name="cardCvv2"
                                                    placeholder="*ספרות בגב הכרטיס"
                                                    type="number"
                                                    value={cardCvv2}
                                                    onChange={this.handleChange}
                                                    cssClass="e-outline"
                                                    floatLabelType="Auto"
                                                />
                                                <label className='e-error' htmlFor='cardCvv2' />
                                            </div>
                                            <div className="col-6">
                                                <DropDownListComponent 
                                                    id='paymentsNumber'
                                                    name="paymentsNumber"
                                                    placeholder="*מספר תשלומים"
                                                    value={paymentsNumber}
                                                    change={this.handleDDLChange}
                                                    dataSource={payments}
                                                    fields={{ text: 'name', value: 'code' }}                                                    
                                                    noRecordsTemplate={"אין נתונים לתצוגה"}
                                                    cssClass="e-outline"
                                                    floatLabelType="Auto"
                                                    enableRtl={true}
                                                />
                                                <label className='e-error' htmlFor='paymentsNumber' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="row mb-3">
                                    <div className="col text-left font-weight-bold">
                                        סף הכל לתשלום: {(orderTotal).toLocaleString(navigator.language, {minimumFractionDigits: 2})}
                                    </div>
                                </div> */}
                                <div className="row form-group">
                                    <div className="col">
                                        <CheckBoxComponent
                                            id="confirm"
                                            name="confirm"
                                            label="אני מאשר כי קראתי את <a href='/terms' target='_blank'>תנאי השימוש</a> באתר."
                                            change={this.handleCheck}
                                            checked={confirm}
                                        >
                                        </CheckBoxComponent>
                                        <label className='e-error' htmlFor='confirm'/>
                                    </div>
                                </div>
                                <div className="row form-group">
                                    <div className="col">
                                        <CheckBoxComponent
                                            id="marketing"
                                            name="marketing"
                                            label='ברצוני לקבל מידע ופרסום על הטבות ועדכונים דוא"ל ו-SMS.'
                                            change={this.handleCheck}
                                            checked={marketing}
                                        >
                                        </CheckBoxComponent>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col text-left">
                                        <ButtonComponent
                                            onClick={this.submitClick}
                                            isPrimary={true}
                                            cssClass="e-btn-bb"
                                            type='button'
                                        >
                                            {btnCheckoutLoader ? <PulseLoader color={'#fff'} loading={btnCheckoutLoader} size={10} /> : "בצע רכישה"}
                                        </ButtonComponent>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ModalMessage visible={modalShow} close={this.modalClose} content={modalBody} header={modalHeader}></ModalMessage>
                </form>
            </>
        );
    }
}