import React, { Component } from "react";
import { Helmet } from "react-helmet";
import safetysystemBanner from "../assets/safetysystem-banner.png";

export default class SafetySystem extends Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <>
                <Helmet> 
                    <title>Baby beep - חובת התקנת מערכת למניעת שכחת ילדים ברכב פרטי</title>
                    <meta property="og:title" content="Baby beep - חובת התקנת מערכת למניעת שכחת ילדים ברכב פרטי" />
                    <meta name="description" content="חובת התקנת המערכת תחול על כל רכב פרטי המסיע ילדים מתחת לגיל 4. על המערכת לעמוד בדרישות של התקן האיטלקי, אך ככל שתהייה תקינה רלוונטית נוספת בעולם ניתן יהא לאמץ גם אותה. על מנת להימנע מרגולציה מעמיסה הוחלט להסתמך על תקן קיים ולחייב עמידה בו, ולא להוסיף חובת עמידה בתקן חדש." />
                    <meta property="og:description" content="חובת התקנת המערכת תחול על כל רכב פרטי המסיע ילדים מתחת לגיל 4. על המערכת לעמוד בדרישות של התקן האיטלקי, אך ככל שתהייה תקינה רלוונטית נוספת בעולם ניתן יהא לאמץ גם אותה. על מנת להימנע מרגולציה מעמיסה הוחלט להסתמך על תקן קיים ולחייב עמידה בו, ולא להוסיף חובת עמידה בתקן חדש." />
                </Helmet>
                
                <div className="container privacy py-5">
                    <div className="row">
                        <div className="col">
                            <img src={safetysystemBanner} className="img-fluid"></img>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-8 my-3">
                            <h1>תקנות חדשות אודות חובת התקנת מערכת למניעת שכחת ילדים בכל רכב פרטי</h1>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-md-12 text-justify">
                            <p>
                                חובת התקנת המערכת תחול על כל רכב פרטי המסיע ילדים מתחת לגיל 4. על המערכת לעמוד בדרישות של התקן האיטלקי, אך ככל שתהייה תקינה רלוונטית נוספת בעולם ניתן יהא לאמץ גם אותה. על מנת להימנע מרגולציה מעמיסה הוחלט להסתמך על תקן קיים ולחייב עמידה בו, ולא להוסיף חובת עמידה בתקן חדש.
                            </p>
                            <p>
                                הקנס בגין הסעת ילדים מתחת לגיל 4 ללא התקנת מערכת כזו יעמוד על 250 ₪ וכן 4 נקודות.
                            </p>
                            <p>
                                יש לציין כי בהצעה המקורית לתיקון התקנות הוצע להחיל את חובת התקנת המערכת על כל רכב פרטי המסיע ילדים מתחת לגיל 5, אך בשל הרצון להתאים את דרישות המערכת לתקן קיים (התקן האיטלקי) ובעיקר לאור העובדה כי גילם של הרוב המוחלט של פעוטות שנפגעו כתוצאה מהשארתם ברכב לא עלה על 3, הוחלט להטיל את חובת התקנת המערכת למי שמסיע ילדים מתחת לגיל 4. תשומת הלב לכך, כי החובה תחול על כל מי שיסיע ילד עד גיל 4 ברכב פרטי, ולא רק על הורים או בני משפחה.
                            </p>
                            <p>
                                התקנות ייכנסו לתוקף ביום 1.8.2021 כהוראת שעה ויעמדו בתוקף עד ליום 1.8.2023. אכיפת התקנות בפועל תחל ביום 1.1.2022.
                            </p>
                            <p>
                                לקראת תום מועד תוקף התקנות, יידרש משרד התחבורה להציג מחקר בנושא על מנת לבחון את הארכתן.
                            </p>
                            <p>
                                <a href="https://www.gov.il/he/departments/news/20210310_child_safety_system" rel="noopener noreferrer" target="_blank">לקריאת התקנות באתר משרד התחבורה</a>
                            </p>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}