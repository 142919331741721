import http from "../http-common";

class OrderService {
    getAll() {
        return http.get("/articles/getall");
    }

    get(id) {
        return http.get(`/articles/${id}`);
    }

    create(data) {
        return http.post("/api/v1/orders", data);
    }

    update(id, data) {
        return http.post(`/articles/${id}`, data);
    }

    delete(id) {
        return http.get(`/articles/${id}`);
    }

    findByTitle(title) {
        return http.get(`/articles/title=${title}`);
    }
}

export default new OrderService();