import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { FaCcVisa, FaCcMastercard, FaCcDinersClub, FaCcAmex } from 'react-icons/fa';
import CardSection from '../components/cardsection';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import MailIcon from '@material-ui/icons/Mail';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default () => {
  return (
    <>
      <CardSection className="links">
        <div className="container">
            <div className="row">
                <div className="col-12 col-md-4 col-lg-3 mb-4 mb-md-0 text-center text-md-right">
                    <h4>תפריט</h4>
                    <ul>
                        <li className="font-weight-bold"><Link to="/order">הזמן עכשיו</Link></li>
                        <li><Link to="/">ראשי</Link></li>
                        <li>הרחבת אחריות</li>
                        <li><Link to="/terms">תנאי שימוש</Link></li>
                        <li><Link to="/privacy">מדיניות פרטיות</Link></li>
                        <li><a rel="noopener noreferrer" href="https://static.babybeep.co.il/pdf/21_013-034646-01.pdf">אישור תקינות</a></li>
                    </ul>
                </div>
                <div className="col-12 col-md-4 col-lg-3 mb-4 mb-md-0 text-center text-md-right">
                    <h4>יצירת קשר</h4>
                    <ul>
                        <li><a rel="noopener noreferrer" href="tel:+972-2-6307779"><HeadsetMicIcon className="link-icon ml-2"></HeadsetMicIcon>02-6307779</a></li>
                        <li><a rel="noopener noreferrer" target="_blank" href="https://api.whatsapp.com/send?phone=972509096656"><WhatsAppIcon className="link-icon ml-2"></WhatsAppIcon>WhatsApp</a></li>
                        <li><a rel="noopener noreferrer" href="mailto:info@babybeep.co.il"><MailIcon className="link-icon ml-2"></MailIcon>info@babybeep.co.il</a></li>
                        <li><LocationOnIcon className="link-icon ml-2"></LocationOnIcon>ת.ד 11283 מיקוד 91112</li>
                    </ul>
                </div>
                <div className="col-12 col-md-4 col-lg-6 mb-4 mb-md-0 text-left">
                    <LazyLoadImage src="https://static.babybeep.co.il/images/babybeepfooter.png" className="img-fluid" alt="לוגו Baby beep עבור הפוטר התחתון"></LazyLoadImage>
                </div>
            </div>
        </div>
      </CardSection>
      <footer>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-4 text-center text-md-right cc-icons">
              <FaCcVisa className="ml-2"></FaCcVisa>
              <FaCcMastercard className="ml-2"></FaCcMastercard>
              <FaCcDinersClub className="ml-2"></FaCcDinersClub>
              <FaCcAmex></FaCcAmex>
            </div>
            <div className="col-md-4 text-center">
              כל הזכויות שמורות Team6Tech &copy;
            </div>
            <div className="col-md-4 text-center text-md-left" dir="ltr">
              <a href="http://skytech.co.il" target="_blank" rel="noopener noreferrer"><img src="https://static.babybeep.co.il/images/skytech.png" alt="לוגו סקייטק"></img></a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};