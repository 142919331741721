import React from 'react';
import './App.scss';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Menu from './components/menu';
import Home from './pages/home';
import Order from './pages/order';
import Shipping from './pages/shipping';
import Checkout from './pages/checkout';
import Contact from './pages/contact';
import End from './pages/end';
import Terms from './pages/terms';
import Privacy from './pages/privacy';
import Footer from './components/footer';
import PageNotFound from './components/pagenotfound';
import { enableRtl } from '@syncfusion/ej2-base';
import WhatsAppWidget from 'react-whatsapp-widget';
import TagManager from 'react-gtm-module';
import HttpsRedirect from 'react-https-redirect';
import ReactPixel from 'react-facebook-pixel';
import Warranty from './pages/warranty';
import SafetySystem from './pages/safetysystem';
import 'react-whatsapp-widget/dist/index.css';
import Article from './pages/article';
import Article100001 from './pages/article10001';

// Enables Right to left alignment for all controls
enableRtl(true);

const tagManagerArgs = {
    gtmId: 'GTM-N6NKPJF'
}
TagManager.initialize(tagManagerArgs)

const advancedMatching = {};
const options = {
  autoConfig: true,
  debug: false,
};
ReactPixel.init('915110859262172', advancedMatching, options);

function App() {
  return (
    <HttpsRedirect>
      <Router>
        <Menu></Menu>
        <Switch>
          <Route exact path="/" component={Home}></Route>
          <Route path="/order" component={Order}></Route>
          <Route path="/shipping" component={Shipping}></Route>
          <Route path="/checkout" component={Checkout}></Route>
          <Route path="/contact" component={Contact}></Route>
          <Route path="/end" component={End}></Route>
          <Route path="/terms" component={Terms}></Route>
          <Route path="/privacy" component={Privacy}></Route>
          <Route path="/warranty" component={Warranty}></Route>
          <Route path="/safetysystem" component={SafetySystem}></Route>
          <Route path="/articles/100000" component={Article}></Route>
          <Route path="/articles/100001" component={Article100001}></Route>
          <Route path="*" component={PageNotFound}></Route>
        </Switch>
        <Footer></Footer>
        <div className="fixed-bottom">
          <WhatsAppWidget 
            phoneNumber='972509096656'
            companyName="Baby beep"
            sendButton="שלח"
            textReplyTime="עונה בדרך כלל תוך שעה"
            message="תודה שפניתם אלינו, כיצד נוכל לסייע?" />
          </div>
      </Router>
   </HttpsRedirect>
  );
}

export default App;
