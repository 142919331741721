import React, { Component } from "react";
import { Helmet } from "react-helmet";

export default class Article extends Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <>
                <Helmet> 
                    <title>שכחת את הילד לרגע, תזכור לכל החיים</title>
                    <meta property="og:title" content="שכחת את הילד לרגע, תזכור לכל החיים" />
                    <meta name="description" content="שיתוף פעולה ישראלי-איטלקי הביא לארץ את מערכת הבטיחות למניעת שכחת ילדים ברכב, שמשלבת בין ערכה איכותית להתקנה בכיסא הבטיחות לאפליקציה מתקדמת ונגישה" />
                    <meta property="og:description" content="שיתוף פעולה ישראלי-איטלקי הביא לארץ את מערכת הבטיחות למניעת שכחת ילדים ברכב, שמשלבת בין ערכה איכותית להתקנה בכיסא הבטיחות לאפליקציה מתקדמת ונגישה" />
                    <meta property='og:type' content='article' />
                </Helmet>
                
                <div className="container article py-5">
                    <div className="row">
                        <div className="col-md-12 my-3">
                            <h1>Baby beep: שכחת את הילד לרגע, תזכור לכל החיים</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 my-3">
                            <h2>שיתוף פעולה ישראלי-איטלקי הביא לארץ את מערכת הבטיחות למניעת שכחת ילדים ברכב, שמשלבת בין ערכה איכותית להתקנה בכיסא הבטיחות לאפליקציה מתקדמת ונגישה</h2>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-md-12 text-justify">
                            <p>
                                <img src="https://static.babybeep.co.il/images/561454708.png" className="img-fluid" alt="מערכת Baby beep"></img>
                            </p>
                            <p>
                                על פי נתוני משרד הבריאות, בין השנים 2008-2016 נשכחו 381 ילדים ברכב, 19 מהם נפטרו. החל מהקיץ הקרוב תחויב בחוק התקנת מערכת למניעת שכחת ילדים עד גיל 5 ברכבים פרטיים.
                            </p>
                            <p>
                                מערכת Baby beep למניעת שכחת ילדים ברכב היא פרי שיתוף פעולה בין יזמים ישראלים ואיטלקים המשווקים אותה כבר היום באיטליה, שם החוק מחייב התקנת מערכות מסוג זה. המערכת, העומדת בתקנים הנדרשים והמחמירים באיטליה, הותאמה לצורכי השוק הישראלי. היא כוללת כרית נוחה, דקה והיפואלרגנית המיוצרת באיטליה ומותאמת לכל מושבי הבטיחות ולכל סוגי הרכבים, יחד עם חיישן משקל שמזהה שהילד התיישב במושבו.
                            </p>
                            <p>
                                המערכת מנוהלת באמצעות אפליקציית Baby beep, פיתוח כחול לבן המותקן בטלפון של ההורה ומחובר לכרית באמצעות בלוטות'. כאשר ההורה מתרחק מספר מטרים מהרכב, מופעל צליל התרעה המלווה גם ברטט ובחיווי ויזואלי על גבי מסך הטלפון. אם לא מתקבלת תגובה כעבור שתי דקות, מועברת הודעה לאנשי קשר שהוגדרו מראש, כולל תיעוד של מיקום הרכב על גבי מפה.
                            </p>
                            <p>
                                <iframe frameborder="0" src="//www.youtube.com/embed/qyT0KGcDrO8" width="100%" height="360"></iframe>
                            </p>
                            <p>
                                מערכת Baby beep הושקה השבוע והיא תומכת באנדרואיד (מערכת הפעלה 5 ומעלה) ואייפון (מערכת הפעלה 11 ומעלה). מערכת התמיכה הלוגיסטית והטכנית של המוצר כוללת מוקד טלפוני אנושי, וואטסאפ זמין עם מענה של נציג מוסמך, אתר אינטרנט ועמודי פייסבוק ואינסטגרם פעילים.
                            </p>
                            <p>
                                <a href="https://www.haaretz.co.il/labels/apps/1.9546229" rel="noopener noreferrer" target="_blank">לקריאת הכתבה המלאה</a>
                            </p>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}