import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link, NavLink } from "react-router-dom";
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';

export default class Menu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      navExpanded: false
    };
  }

  handleNavCollapse = () => {
    this.setState({ navExpanded: !this.state.navExpanded });
  }

  setNavClose = () => {
    this.setState({ navExpanded: !this.state.navExpanded });
  }

  render() {
    return (
      <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top navbar-babybeep">
        <a className="navbar-brand" href="/"><img src="https://static.babybeep.co.il/images/logo.png" className="img-fluid" alt="לוגו Baby beep"></img></a>
        <div>
          <Link to="/order">
            <ButtonComponent
                isPrimary={true}
                type='button'
                className="navbar-order-now ml-3 d-lg-none"
            >
                הזמן עכשיו
            </ButtonComponent>
          </Link>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded={(this.state.navExpanded)} aria-label="תפריט נגלל" onClick={this.handleNavCollapse}>
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
         <div className={`${this.state.navExpanded === false ? 'collapse' : ''} navbar-collapse`} id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item d-none d-xl-block">
              <Link to="/order" className="nav-link" onClick={this.setNavClose}>
                <ButtonComponent
                    isPrimary={true}
                    cssClass="e-btn-bb"
                    type='button'
                >
                    הזמן עכשיו
                </ButtonComponent>
              </Link>
            </li>
            <li className="nav-item">
              <NavLink to="/" exact className="nav-link" activeClassName='active' onClick={this.setNavClose}>ראשי</NavLink>
            </li>
            {/* <li className="nav-item">
              <NavLink to="/warranty" className="nav-link" activeClassName='active' onClick={this.setNavClose}>הרחבת אחריות</NavLink>
            </li> */}
            <li className="nav-item">
              <a className="nav-link" rel="noopener noreferrer" onClick={this.setNavClose} href="https://static.babybeep.co.il/pdf/babybeep_manual_heb_v1.pdf" target="_blank" aria-label="לחץ כאן להורדת מדריך למשתמש">מדריך למשתמש</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" rel="noopener noreferrer" onClick={this.setNavClose} href="https://static.babybeep.co.il/pdf/babybeep_manual_heb_short_v1.pdf" target="_blank" aria-label="לחץ כאן להורדת מדריך ההתקנה">מדריך ההתקנה</a>
            </li>
            <li className="nav-item">
              <NavLink to="/contact" className="nav-link" activeClassName='active' onClick={this.setNavClose}>צור קשר</NavLink>
            </li>
            <li className="nav-item">
              <a className="nav-link d-none d-xl-block" rel="noopener noreferrer" onClick={this.setNavClose} href="https://www.youtube.com/watch?v=qyT0KGcDrO8&ab_channel=Babybeep" target="_blank" aria-label="לחץ כאן לצפייה במדריך בעזרה וירטואלית"><img src="https://static.babybeep.co.il/images/youtube.png" alt="צפייה בעזרה וירטואלית"></img></a>
              <a className="nav-link d-xl-none" rel="noopener noreferrer" onClick={this.setNavClose} href="https://www.youtube.com/watch?v=qyT0KGcDrO8&ab_channel=Babybeep" target="_blank">עזרה וירטואלית</a>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}