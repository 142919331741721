import React, { Component } from "react";
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import { ToastComponent } from '@syncfusion/ej2-react-notifications';
import ReactPixel from 'react-facebook-pixel';

export default class ProductCard extends Component {
    constructor(props) {
        super(props);

        this.toastPosition = { X: "Left", Y: "Bottom" };

        this.state = {
            productId: this.props.productId || 0,
            productName: this.props.productName || null,
            productImage: this.props.productImage || null,
            productPriceOld: this.props.productPriceOld || 0,
            productPrice: this.props.productPrice || 0,
            productCurrency: this.props.productCurrency || null,
            productCount: 1,
            modalShow: false,
            modalBody: '',
            modalHeader: '',
        }
    }

    handleIncrement = () => {
        this.setState(state => ({ productCount: state.productCount + 1 }));
    };
    
    handleDecrement = () => {
        //no decrement if orderCount smaller or equal 1
        if (this.state.productCount >= 2)
        {
            this.setState(state => ({ productCount: state.productCount - 1 }));
        }
    };

    addToCartClick = () => {
        const { productCount, productId, productPrice, productName, productImage } = this.state;

        let order = {};
        const shoppingCartItem = {
            quantity: productCount,
            productId,
            price: productPrice,
            name: productName,
            image: productImage
        };

        if (localStorage.getItem('order')) {
            order = JSON.parse(localStorage.getItem('order'));
            const shoppingCart = order.shoppingCart;

            // Update item in array
            let items = [...shoppingCart];
            
            var index = items.findIndex(i => i.productId === productId);

            if (index > -1)
            {
                let item = {
                    ...items[index]
                };

                item.quantity += productCount;

                items[index] = item;

                order = { ...order,
                    shoppingCart: [...items ]
                };
            }   
            else {
                order = { ...order,
                    shoppingCart: [...shoppingCart, shoppingCartItem ]
                };
            }
        }
        else {
            order = { 
                shoppingCart: [ shoppingCartItem ]
            };
        }

        localStorage.setItem('order', JSON.stringify(order));

        ReactPixel.track("AddToCart", {currency: "ILS", value: productPrice * productCount});

        window.scroll({top: 0, left: 0, behavior: 'smooth' });
        this.toastInstance.content = `מוצר ${productName} התווסף לעגלת הקניות שלך`;
        this.toastInstance.show();
        this.props.updateOrder();
    }

    render() {

        const { productName, productImage, productPrice, productCount, productCurrency, productPriceOld } = this.state;
        const displayCounter = productCount > 0;

        return (
            <div className="product-card text-center upWards">
                <div>
                    <h2>{productName}</h2>
                </div>
                <div className="product-card-image">
                    <img src={productImage} alt={productName}></img>
                </div>
                {productPriceOld > 0 &&
                    <div className="product-card-price-old text-muted">
                        <del>{productPriceOld} {productCurrency}</del>
                    </div>
                }
                <div className="product-card-price">
                    <h3>{productPrice} {productCurrency}</h3>
                </div>
                <div>
                    <div className='e-btn-group e-rtl' aria-label="בחירת כמות מוצרים">
                        <ButtonComponent cssClass="e-flat" onClick={this.handleIncrement} aria-label="הוסף אחד לכמות המוצרים" type="button">+</ButtonComponent>
                        { displayCounter && <ButtonComponent cssClass="e-outline" disabled={true} aria-label={`סך הכל מוצרים ${productCount}`}>{productCount}</ButtonComponent> }
                        { displayCounter && <ButtonComponent cssClass="e-flat" onClick={this.handleDecrement} aria-label="הורד אחד לכמות המוצרים" type="button">-</ButtonComponent> }
                    </div>
                </div>
                <div className="mb-4 text-muted">
                    סה"כ {(productPrice * productCount).toLocaleString(navigator.language, {minimumFractionDigits: 2})} {productCurrency}
                </div>
                <div>
                    <ButtonComponent
                        onClick={this.addToCartClick}
                        isPrimary={true}
                        className="btn-shoppingCart"
                        cssClass="e-outline"
                        type='button'
                    >
                        הוסף לעגלה
                        <AddShoppingCartIcon></AddShoppingCartIcon>
                    </ButtonComponent>
                </div>
                <ToastComponent 
                    ref={toast => this.toastInstance = toast}
                    title="התווסף מוצר לעגלה"
                    content=""
                    position={this.toastPosition}
                    showProgressBar={true}
                    timeOut={3500}
                    showCloseButton={true}
                />
            </div>
        );
    }
}