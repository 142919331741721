import React, { Component } from "react";
import { Helmet } from "react-helmet";
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import MailIcon from '@material-ui/icons/Mail';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { TextBoxComponent, FormValidator } from '@syncfusion/ej2-react-inputs';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import ContactService from '../services/contact.service'
import PulseLoader from "react-spinners/PulseLoader";
import ModalMessage from '../components/modalMessage'

export default class Contact extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fullname: '',
            email: '',
            phone: '',
            content: '',
            modalBody: '',
            modalShow: false,
            contentLength: 0,
            btnLoader: false
        };
    }
    
    componentDidMount() {
        window.scrollTo(0, 0)

        const options = {
            rules: {
                'fullname': {
                    required: [true, 'שדה חובה'],
                    maxLength: [50, 'מקסימום {0} תווים']
                },
                'email': {
                    required: [true, 'שדה חובה'],
                    email: [true, 'דואר אלקטרוני לא תקני'],
                    maxLength: [256, 'מקסימום {0} תווים']
                },
                'phone': {
                    required: [true, 'שדה חובה'],
                    number: [true, 'מספר טלפון לא תקני'],
                    minLength: [8, 'מספר טלפון לא תקני'],
                    maxLength: [20, 'מקסימום {0} תווים']
                },
                'content': {
                    required: [true, 'שדה חובה'],
                    maxLength: [500, 'מקסימום {0} תווים']
                },
            }
        }

        // initialize the form validator
        this.formValidator = new FormValidator('#form1', options);
    }

    handleChange = (e) => {
        const value = e.value;
        const name = e.target.name;

        if (name === 'content') {
            this.setState({
                ...this.state,
                [name]: value,
                contentLength: value.length
            });
        }
        else {
            this.setState({
                ...this.state,
                [name]: value
            });
        }
    }

    submitClick = () => {
        if (this.formValidator.validate()) {

            this.setState({
                btnLoader: true
            });

            const { fullname, email, phone, content} = this.state;
            const data = {
                "fullname": fullname,
                "email": email,
                "phone": phone,
                "content": content
            }

            ContactService.create(data)
                .then(response => {

                    if (response.data.status === 'success')
                    {
                        const modalBody = `<p>נציג יצור עימך  קשר בהקדם האפשרי כדי לתת מענה לפנייתך.</p><p>מספר הפנייה: ${response.data.data?.contactId}</p><p>תודה רבה, שירות לקוחות Baby beep</p>`;

                        this.setState({
                            fullname: '',
                            email: '',
                            phone: '',
                            content: '',
                            modalHeader: 'פנייתך התקבלה בהצלחה',                            
                            modalBody,
                            modalShow: true,
                            btnLoader: false,
                            contentLength: 0
                        });
                    }
                    else {
                        this.setState({
                            modalHeader: 'אופס...',
                            modalBody: 'ארעה שגיאה בלתי צפויה, נסה שנית, במידה והתקלה חוזרת, בדוק את חיבור האינטרנט בו אתה גולש כרגע, במידה ועדיין התקלה חוזרת, נסה שוב במועד מאוחר יותר.',
                            modalShow: true,
                            btnLoader: false
                        });
                    }
                })
                .catch(e => {
                    this.setState({
                        modalHeader: 'אופס...',
                        modalBody: 'ארעה שגיאה בלתי צפויה, נסה שנית, במידה והתקלה חוזרת, בדוק את חיבור האינטרנט בו אתה גולש כרגע, במידה ועדיין התקלה חוזרת, נסה שוב במועד מאוחר יותר.',
                        modalShow: true,
                        btnLoader: false
                    });
                });
        }
        else {
            //document.getElementsByTagName("form")[0]?.querySelector("input.e-error")?.focus();
        }
    }

    modalClose = () => {
        this.setState({
            modalShow: false
        })
    }

    render() {

        const { fullname, email, phone, content, contentLength, btnLoader } = this.state;

        return (
            <>
                <Helmet>                   
                    <title>Baby beep - צור קשר</title>
                    <meta property="og:title" content="Baby beep - צור קשר" />
                    <meta name="description" content="מחלקת שירות הלקוחות של Baby beep עומדת לשירותכם בכל שאלה או פנייה" />
                    <meta property="og:description" content="מחלקת שירות הלקוחות של Baby beep עומדת לשירותכם בכל שאלה או פנייה" />
                    {/* <meta property="og:url" content={process.env.REACT_APP_DOMAIN + window.location.pathname} /> */}
                    {/* <meta property="og:type" content="article" /> */}
                </Helmet>
                
                <div className="container contact py-10">
                    <div className="row">
                        <div className="col-md-7 mb-2">
                            <h1>צור קשר</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-7 mb-2">
                            <h2>מחלקת שירות הלקוחות של Baby beep עומדת לשירותכם בכל שאלה או פנייה</h2>
                        </div>
                    </div>
                    {/* <div className="row justify-content-center">
                        <div className="col-8 mb-5">
                            <h3>לנוחיותכם צירפנו מדריכי הפעלה המצורפים לעמוד זה</h3>
                        </div>
                    </div> */}
                    <div className="row justify-content-center">
                        <div className="col-md-7">
                            <form id='form1' autoComplete="off">
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <TextBoxComponent 
                                            id="fullname"
                                            name="fullname"
                                            placeholder="*שם מלא"
                                            onChange={this.handleChange}
                                            value={fullname}
                                            cssClass="e-outline"
                                            floatLabelType="Auto"
                                        />                                        
                                        <label className='e-error' htmlFor='fullname' />
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <TextBoxComponent
                                            id="phone"
                                            name="phone"
                                            placeholder="טלפון"
                                            type="tel"
                                            onChange={this.handleChange}
                                            value={phone}
                                            cssClass="e-outline"
                                            floatLabelType="Auto"
                                        />
                                        <label className='e-error' htmlFor='phone' />
                                    </div>
                                </div>
                                <div className="row form-group">
                                    <div className="col-12">
                                        <TextBoxComponent
                                            id="email"
                                            name="email"
                                            placeholder="*דואר אלקטרוני"
                                            onChange={this.handleChange}
                                            value={email}
                                            cssClass="e-outline"
                                            floatLabelType="Auto"
                                        />                                        
                                        <label className='e-error' htmlFor='email' />
                                    </div>
                                </div>
                                <div className="row form-group">
                                    <div className="col-12">
                                        <TextBoxComponent
                                            id="content"
                                            name="content"
                                            placeholder="*תוכן הפנייה"
                                            multiline
                                            onChange={this.handleChange}
                                            value={content}
                                            cssClass="e-outline"
                                            floatLabelType="Auto"
                                        />
                                        {contentLength >= 1 && `${contentLength}/500`}
                                        <label className='e-error' htmlFor='content' />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 text-left">
                                        <ButtonComponent
                                            onClick={this.submitClick}
                                            isPrimary={true}
                                            cssClass="e-btn-bb"
                                            type='button'
                                        >
                                            {btnLoader ? <PulseLoader color={'#fff'} loading={btnLoader} size={10} /> : "שלח"}
                                        </ButtonComponent>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-md-3 mx-auto">
                            <h4>יצירת קשר</h4>
                            <ul>
                                <li><a rel="noopener noreferrer" href="tel:+972-2-6307779"><HeadsetMicIcon className="link-icon ml-2"></HeadsetMicIcon>02-6307779</a></li>
                                <li><a rel="noopener noreferrer" target="_blank" href="https://api.whatsapp.com/send?phone=972509096656"><WhatsAppIcon className="link-icon ml-2"></WhatsAppIcon>WhatsApp</a></li>
                                <li><a rel="noopener noreferrer" href="mailto:info@babybeep.co.il"><MailIcon className="link-icon ml-2"></MailIcon>info@babybeep.co.il</a></li>
                                <li><LocationOnIcon className="link-icon ml-2"></LocationOnIcon>ת.ד 11283 מיקוד 91112</li>
                                <li>
                                    <a href="https://www.facebook.com/babybeepil/" target="_blank" rel="noopener noreferrer"><img src="https://static.babybeep.co.il/images/facebook.png" alt="לוגו פייסבוק"></img></a>
                                    <a href="https://www.instagram.com/babybeep_il/" target="_blank" rel="noopener noreferrer"><img src="https://static.babybeep.co.il/images/instagram.png" alt="לוגו אינסטגרם"></img></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <ModalMessage visible={this.state.modalShow} close={this.modalClose} content={this.state.modalBody} header={this.state.modalHeader}></ModalMessage>
            </>
        );
    }
}