import React from "react";
import { DialogComponent } from '@syncfusion/ej2-react-popups';

export default ({children, visible = null, content = null, header = null, close = null}) => {
  return (
    <DialogComponent
        visible={visible}
        close={close}
        closeOnEscape={true}
        enableRtl={true}
        showCloseIcon={true}
        isModal={true}
        content={content}
        header={`<h2>${header}</h2>`}
        width='500px'
    >
        {children}
    </DialogComponent>
  );
};