import React from "react";

export default ({children, className = null, headline = null}) => {
  return (
    <section className={`section-card ${className}`}>
        {headline &&
            <div className="container">
                <div className="row">
                    <div className="col text-center section-card-headline mb-5">
                        <h2>{headline}</h2>
                    </div>
                </div>
            </div>
        }
        {
            children
        }
    </section>
  );
};