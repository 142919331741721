import React, { Component } from "react";
import { Helmet } from "react-helmet";

export default class Privacy extends Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <>
                <Helmet> 
                    <title>Baby beep - מדיניות פרטיות</title>
                    <meta property="og:title" content="Baby beep - מדיניות פרטיות" />
                    <meta name="description" content="חברת Team6tech מתייחסת בכבוד לפרטיותך והיא חשובה לנו. מדיניות פרטיות זו מסדירה את האופן שבו עושה החברה שימוש במידע אותו היא מקבלת ממשתמשים באתר Babybeep.co.il" />
                    <meta property="og:description" content="חברת Team6tech מתייחסת בכבוד לפרטיותך והיא חשובה לנו. מדיניות פרטיות זו מסדירה את האופן שבו עושה החברה שימוש במידע אותו היא מקבלת ממשתמשים באתר Babybeep.co.il" />
                </Helmet>
                
                <div className="container privacy py-10">
                    <div className="row">
                        <div className="col-8 mb-2">
                            <h1>מדיניות פרטיות</h1>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-md-12 text-justify">
                            <p>
                                1.	חברת Team6tech מתייחסת בכבוד לפרטיותך והיא חשובה לנו. מדיניות פרטיות זו מסדירה את האופן שבו עושה החברה שימוש במידע אותו היא מקבלת ממשתמשים באתר Babybeep.co.il
                            </p>
                            <p>
                                2.	במסגרת השימוש באתר, החברה עשויה לאסוף מידע באמצעים שלהלן. יובהר, כי לא חלה עליך כל חובה למסור מידע כאמור, ומסירתו נעשית מרצונך החופשי ובעצם השימוש באתר, אתה מביע את הסכמתך לאיסוף, שמירה ושימוש במידע באופן המתואר במדיניות זו.
                            </p>
                            <p>
                                3.	האמור במדיניות פרטיות זו מנוסח מטעמי נוחות בלשון זכר, אולם הוא מתייחס לשני המינים בצורה שווה.
                            </p>
                            <p>
                                4.	תנאי מדיניות הפרטיות המפורטים להלן, מהווים חלק בלתי נפרד מתנאי השימוש. החברה רשאית לשנות מעת לעת את הוראות מדיניות הפרטיות שלה
                            </p>
                            <p>
                                5.	חלק מהשירותים באתר דורשים הרשמה. במסגרת ההרשמה תידרש למסור מידע אישי, כדוגמת שמך, כתובתך, דרכי ההתקשרות עמך או כתובת הדואר האלקטרוני שלך כדי שנוכל לספק לך את המוצר בצורה הטובה והיעילה ביותר. 
                            </p>
                            <p>
                                6.	לחברה מאגר מידע רשום בו ישמרו פרטיך, כמפורט לעיל לטובת ניהול מערך המכירות וניהול תקין של האתר, לענות לבעיות במידה ויהיו כאלו, למשל הפעלת אחריות, נצטרך לדעת מתי ואיך התבצעה הרכישה,  ניהול מאגר לקוחות, לרבות טיפוח ועידוד נאמנות המשתמשים באמצעות ניתוח הרגלי הקניה וקיום מבצעים ותמריצים, דיוור ישיר למטרות אלו ולצרכים שיווקיים, נתוניך לא יעברו לצדדים שלישיים.
                            </p>
                            <p>
                                7.	מובהר בזאת, כי לא חלה על המשתמש כל חובה שבדין למסור את המידע כאמור וכן שמסירת המידע תלויה ברצונו ובהסכמתו של המשתמש. עם זאת, מילוי חלק מהשדות בהליך הרישום לאתר מהווה תנאי הכרחי לשימוש לסיום הליך ההרשמה. המשתמש מצהיר ומתחייב בזאת, כי כל המידע שימסור ו/או יעדכן באתר, הנו נכון אמין ומדויק וכן כי ביצע את ההרשמה ו/או מסר את המידע בשמו ועבור עצמו בלבד ולא בשם ו/או עבור צדדים שלישיים, למעט במקרים בהם הורשה לעשות כן, במפורש. המידע שמסר המשתמש וכן מידע נוסף שיצטבר אודותיו ישמרו במאגר המידע של החברה, המפורט לעיל, ויעשה בו שימוש בהתאם למטרות המאגר המפורטות לעיל ובהתאם למדיניות פרטיות זו ו/או בהתאם להוראות כל דין.
                            </p>
                            <p>
                                8.	כמו כן, רשאית החברה לעשות שימוש במידע שנתקבל מאת המשתמש ו/או במידע שנאסף אודותיו, גם במקרים הבאים: לשם יצירת קשר עם המשתמש במקרה הצורך; לצורך תחזוקת האתר ו/או המערכת; לצורך שיפור המידע והתכנים שהחברה תציע במסגרת האתר למשתמשים באופן אישי ו/או לכלל ו/או לחלק מן המשתמשים באתר.
                            </p>
                            <p>
                                9.	מבלי לגרוע מהאמור לעיל, בכפוף למסירת המשתמש את פרטי ההתקשרות שלו לחברה והסכמתו לקבלת דיוור ישיר, תהיה החברה רשאית לשלוח למשתמש, מעת לעת, בדואר אלקטרוני ו/או באמצעי התקשרות אחרים שמסר המשתמש, מידע בדבר שירותיה וכן מידע שיווקי ופרסומי של החברה
                            </p>

                            <h2>האתר משתמש בעוגיות: ‘Cookies’</h2>
                            <p>
                                10.	אם אינך רוצה לקבל עוגיות, תוכל להימנע מכך על ידי שינוי ההגדרות בדפדפן שלך
                            </p>
                            <p>
                                11.	החברה תעביר את פרטיך לצדדים שלישיים אך ורק לצרכי השלמת רכישה או לצורך שהינו חלק מתהליך הרכישה לדוגמה לחברת הסליקה או חברת טנזילה הסולקת כרטיסי אשראי עבורנו, חברת השליחויות, או במידה והמשתמש הפר את תנאי השימוש או בחר לפגוע או לתבוע את החברה וכו, לא נעביר את פרטיך לצדדים שלישיים לצורך פרסום של חברות אחרות.
                            </p>
                            <p>
                                12.	החברה עושה כל שביכולתה ונעזרת באמצעי אבטחה טכנולוגיים וארגוניים מתקדמים כדי לאבטח את האתר, את התקשורת דרכו ואת המידע המצוי בשליטתה, כנגד ניצול מקרי או מכוון, אובדן, הרס או כנגד גישה על ידי אנשים בלתי מוסמכים או מורשים. ההתקשרות בין המחשב של המשתמש או המבקר באתר לבין מחשבי החברה מאובטחת באמצעות שיטות הצפנה מקובלות, כדי להגן על האתר ועל תוכנו מפני חדירות, פרצות או האזנות בלתי מורשות.
                            </p>
                            <p>
                                13.	בכל שאלה ו/או הבהרה ו/או תגובה בנוגע למדיניות פרטיות זו, או אם ברצונך למחוק, לשנות או לתקן את המידע האישי שלך, אנא שלח דואר אלקטרוני ל-info@babybeep.co.il לחלופין, הינך מוזמן לשלוח מכתב לתא דואר. 11283 מיקוד 91112 ירושלים 
                            </p>

                            <h2>האפליקציה משתמשת במיקום המכשיר ברקע</h2>
                            <p>
                                14.	גישה למיקום ה-GPS תמיד מאפשרת קבלת התראה כאשר האפליקציה נמצאת ברקע. באפליקציית Baby beep אנו דורשים שימוש במיקום המכשיר ברקע כדי שנוכל להתריע למשתמשים באם הרכב נמצא במצב סטטי במשך כ-10 דקות וישנו ילד על הכרית. במצב זה אנו שולחים מסרונים עם מיקום הרכב לאני קשר שהוגדרו בחירום  
                            </p>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}