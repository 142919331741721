import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

export default class ShoppingCart extends Component {
    constructor(props) {
        super(props);

        this.state = {
			shoppingCart: this.props.shoppingCart || null,
			orderTotal: this.props.orderTotal || 0,
			coupon: this.props.coupon || null,
			orderTotalBeforeCoupon: this.props.orderTotalBeforeCoupon || 0,
			showBack: this.props.showBack,
			showEdit: this.props.showEdit
        };
    }

	componentWillReceiveProps(props) {
		this.setState({ 
			shoppingCart: props.shoppingCart || null,
			orderTotal: props.orderTotal || 0,
			coupon: props.coupon || null,
			orderTotalBeforeCoupon: props.orderTotalBeforeCoupon || 0,
			showBack: props.showBack,
			showEdit: props.showEdit
		})
	}

    clearShoppingCart = () => {        
		localStorage.removeItem('order');
		this.props.history.push("/order");
    };

	handleQuantity = (productId, increment, quantity) => {
		if (increment === true || (increment === false && quantity >= 2)) {
			if (localStorage.getItem('order')) {
				let order = JSON.parse(localStorage.getItem('order'));
				const shoppingCart = order.shoppingCart;

				// Update item in array
				let items = [...shoppingCart];
				
				var index = items.findIndex(i => i.productId === productId);

				if (index > -1)
				{
					let item = {
						...items[index]
					};

					if (increment === true)
					{
						item.quantity += 1;
					}
					else {
						item.quantity -= 1;
					}

					items[index] = item;

					order = { ...order,
						shoppingCart: [...items ]
					};

					localStorage.setItem('order', JSON.stringify(order));

					let orderTotal = 0;
					order.shoppingCart.map((item) => (
						orderTotal += (item.price * item.quantity)
					))

					this.setState({
						shoppingCart: order.shoppingCart,
						orderTotal
					})
				}
			}
		}
	}

	removeFromCartClick = (productId) => {
		if (window.confirm("האם אתה בטוח שברצונך למחוק מוצר זה")) {
			const { shoppingCart } = this.state;		

			const array = shoppingCart.filter(item => item.productId !== productId);
			let orderTotal = 0;

			if (localStorage.getItem('order')) {
				let order = JSON.parse(localStorage.getItem('order'));            

				order = { ...order,
					shoppingCart: array
				};
			
				if (order != null) {
					order.shoppingCart.map((item) => (
						orderTotal += (item.price * item.quantity)
					))
				}

				localStorage.setItem('order', JSON.stringify(order));
				this.props.updateOrder();
			}

			this.setState({
				shoppingCart: array,
				orderTotal
			})
		}
	}

	continueClick = () => {
        if (localStorage.getItem('order')) {
            this.props.history.push('/shipping');
        }
    }

    render() {
      
        const { shoppingCart, coupon, orderTotal, orderTotalBeforeCoupon, showBack, showEdit } = this.state;

        return (
          	<>
				<div className="row pb-3">
					<div className="col d-none d-lg-block">

					</div>
					<div className="col font-weight-bold d-none d-lg-block">
						שם המוצר
					</div>
					<div className="col font-weight-bold d-none d-lg-block">
						מחיר
					</div>
					<div className="col font-weight-bold d-none d-lg-block">
						כמות
					</div>
					<div className="col font-weight-bold d-none d-lg-block">
						סה"כ מחיר
					</div>
					{showEdit === true &&
						<div className="col d-none d-lg-block"></div>
					}
					<div className="col d-block d-lg-none font-weight-bold">
						<h3>עגלת הקניות שלי</h3>
					</div>
				</div>
				<hr></hr>
				{shoppingCart.map((item, index) => (
					<>
						<div className="row" key={index}>
							<div className="col-3 d-block d-lg-none">
								<img src={item.image} alt={item.name} width={70}></img>
							</div>
							<div className="col-9 d-block d-lg-none">
								<div className="row mb-4">
									<div className="col-12 font-weight-bold mb-2">
										{item.name}
									</div>
									<div className="col-12 mb-2">
										מחיר ליחידה: {item.price} ₪
									</div>
									{showEdit === false &&
										<>
											<div className="col-6">
												כמות: <span className="font-weight-bold">{item.quantity}</span>
											</div>
											<div className="col-6 text-left">
												{(item.price * item.quantity).toLocaleString(navigator.language, {minimumFractionDigits: 2})} ₪
											</div>

										</>
									}
								</div>
							</div>
							{showEdit === true && 
								<div className="row w-100">
									<div className="col-3 d-block d-lg-none">
										<ButtonComponent
											onClick={() => this.removeFromCartClick(item.productId)}
											isPrimary={true}
											cssClass="e-flat"
											type='button'
										>
											<DeleteOutlineIcon></DeleteOutlineIcon>
										</ButtonComponent>
									</div>
									<div className="col d-block d-lg-none">
										<div className='e-btn-group e-rtl' aria-label="בחירת כמות מוצרים">
											<ButtonComponent cssClass="e-flat" onClick={() => this.handleQuantity(item.productId, true, item.quantity)} aria-label="הוסף אחד לכמות המוצרים" type="button">+</ButtonComponent>
											{ (item.quantity > 0) && <ButtonComponent cssClass="e-outline" disabled={true} aria-label={`סך הכל מוצרים ${item.quantity}`}>{item.quantity}</ButtonComponent> }
											{ (item.quantity > 0) && <ButtonComponent cssClass="e-flat" onClick={() => this.handleQuantity(item.productId, false, item.quantity)} aria-label="הורד אחד לכמות המוצרים" type="button">-</ButtonComponent> }
										</div>
									</div>
									<div className="col text-left d-block d-lg-none">
										{(item.price * item.quantity).toLocaleString(navigator.language, {minimumFractionDigits: 2})} ₪
									</div>
								</div>
							}

							<div className="col d-none d-lg-block">
								<img src={item.image} alt={item.name} width={70}></img>
							</div>
							<div className="col d-none d-lg-block">
								{item.name}
							</div>
							<div className="col d-none d-lg-block">
								{item.price} ₪
							</div>
							{showEdit === true ?
								<div className="col d-none d-lg-block">
									<div className='e-btn-group e-rtl' aria-label="בחירת כמות מוצרים">
										<ButtonComponent cssClass="e-flat" onClick={() => this.handleQuantity(item.productId, true, item.quantity)} aria-label="הוסף אחד לכמות המוצרים" type="button">+</ButtonComponent>
										{ (item.quantity > 0) && <ButtonComponent cssClass="e-outline" disabled={true} aria-label={`סך הכל מוצרים ${item.quantity}`}>{item.quantity}</ButtonComponent> }
										{ (item.quantity > 0) && <ButtonComponent cssClass="e-flat" onClick={() => this.handleQuantity(item.productId, false, item.quantity)} aria-label="הורד אחד לכמות המוצרים" type="button">-</ButtonComponent> }
									</div>
								</div>
								:
								<div className="col d-none d-lg-block">
									{item.quantity}
								</div>
							}
							<div className="col d-none d-lg-block">
								{(item.price * item.quantity).toLocaleString(navigator.language, {minimumFractionDigits: 2})} ₪
							</div>
							{showEdit === true &&
								<div className="col d-none d-lg-block">
									<ButtonComponent
										onClick={() => this.removeFromCartClick(item.productId)}
										isPrimary={true}
										cssClass="e-round"
										type='button'
									>
										<DeleteOutlineIcon></DeleteOutlineIcon>
									</ButtonComponent>
								</div>
							}
						</div>
						<hr></hr>
					</>
				))}
				{coupon !== null && coupon.useCoupon === true &&
					<>
						<div className="row">
							<div className="col-12">
								מימוש קופון: <strong>{coupon.couponName}</strong>
							</div>
							<div className="col-12">
								הנחת קופון: {`${(orderTotalBeforeCoupon * coupon.couponDiscount / 100)} - ₪`}
							</div>
						</div>
						<hr></hr>
					</>
				}
				<div className="row">
					<div className="col-12 d-block d-lg-none text-left mb-4">
						סה"כ מחיר: {(orderTotal).toLocaleString(navigator.language, {minimumFractionDigits: 2})} ₪
					</div>
					{(showBack === true)  &&
						<div className="col-6 d-block d-lg-none mb-4">
							<Link to="/order">
								<ButtonComponent
									cssClass="e-outline e-primary e-block"
								>
									חזרה לעגלה
								</ButtonComponent>
							</Link>
						</div>
					}
					{(showBack === false)  &&
						<div className="col-6 d-block d-lg-none">
							<ButtonComponent
								onClick={this.continueClick}
								isPrimary={true}
								cssClass="e-block btn-shopping-cart"
							>
								המשך לתשלום
							</ButtonComponent>
						</div>
					}
					{showEdit === true &&
						<div className="col-6 d-block d-lg-none">
							<ButtonComponent
								onClick={this.clearShoppingCart}
								cssClass="e-outline e-primary e-block btn-shopping-cart"
							>
								נקה עגלה
							</ButtonComponent>
						</div>
					}
				</div>

				<div className="row">
					{(showBack === true)  &&
						<div className="col d-none d-lg-block">
							<Link to="/order">
								<ButtonComponent
									cssClass="e-link"
								>
									חזרה לעגלה
								</ButtonComponent>
							</Link>
						</div>
					}
					{(showBack === false)  &&
						<div className="col d-none d-lg-block">
							<ButtonComponent
								onClick={this.continueClick}
								isPrimary={true}
								cssClass="btn-shopping-cart"
							>
								המשך לתשלום
							</ButtonComponent>
						</div>
					}
					{showEdit === true &&
						<div className="col d-none d-lg-block">
						
							<ButtonComponent
								onClick={this.clearShoppingCart}
								cssClass="e-link"
							>
								נקה עגלה
							</ButtonComponent>
						</div>
												
					}
					<div className="col d-none d-lg-block"></div>
					<div className="col d-none d-lg-block"></div>
					<div className="col d-none d-lg-block">
						סה"כ מחיר:
					</div>
					<div className="col d-none d-lg-block">
						{(orderTotal).toLocaleString(navigator.language, {minimumFractionDigits: 2})} ₪
					</div>
				</div>
			</>
        );
    }
}